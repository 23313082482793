@import "../abstracts/variables";
// Making tabs look like pills
.nav a {
  text-decoration: none;
  cursor: pointer;
}

.nav-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.animationHeaderInline {
  display: inline-flex;
}

.nav.menubarnav {
  background: #fef3f4;
  display: flex;
  list-style: none;
  font-size: 14px;
  padding: 5px 10px;
  margin: 0;
  height: 100%;
  width: 100%;
  margin-bottom: -50px;
  padding-bottom: 53px;
  transition: transform .3s ease-in-out;
  overflow: auto;
  transition-timing-function: ease;
  scroll-behavior: smooth;

  .tabsMoreLeft,
  .tabsMoreRight {
    font-size: 1.5em;
    line-height: 1.5em;
    padding: 0 5px 0 0;
    top: 0;
    opacity: 0.85;
    position: absolute;
    z-index: 1;
    height: 41px;
    width: 24px;
    background: white;
    transition: all .15s ease-in-out;
    cursor: pointer;

    @media only screen and (min-width: map-get($breakpoints, small)) {
      top: 3px;
    }

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }

  .tabsMoreLeft {
    margin: 0 0 0 -10px;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.1);
  }

  .tabsMoreRight {
    right: 0;
    box-shadow: -3px 0 5px rgba(0, 0, 0, 0.1);

    @media only screen and (min-width: map-get($breakpoints, small)) {
      right: 16px;
    }

    @media only screen and (min-width: map-get($breakpoints, large)) {
      right: 32px;
    }
  }

  @media only screen and (min-width: map-get($breakpoints, small)) {
    border-top: 3px solid $brand-color;
  }

  a {
    display: block;
    padding: 6px 10px;
    background: #e2dfdf;
    color: black;
    border-radius: 50px;
    margin: 0 10px 2px 0;
    white-space: nowrap;
  }

  li.active a {
    background: #3398db;
    color: white;
  }

  @media only screen and (min-width: map-get($breakpoints, small)) {
    right: 16px;
  }
}

.teambarnav,
.tabbarnav,
.timelinebarnav {
  display: flex;
  width: 100%;
  padding-left: 8px;
  font-size: 13px;

  .tabsMoreLeft,
  .tabsMoreRight {
    display: none;
    transition: all .15s ease-in-out;
  }

  @media only screen and (max-width: 589px) {
    max-width: 100%;
    overflow: scroll;
  }
}

.tabbarnav,.timelinebarnav,.teambarnav  {
  > .nav-item {
    display: flex;
    flex: 1;
    transition-duration: .25s;
    list-style: none;
    text-align: center;
    position: relative;
    cursor: pointer;
    color: white;
    margin-right: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    margin-bottom: unset;
  }

  .nav-link {
    flex: 1;
    background: #f3d9db;
    color: black;
    border: 0;
    padding: 8px 6px;
    border-radius: 6px 6px 0px 0px;

    &.active {
      background: white;
    }
  }

  @media only screen and (max-width: 320px) {
    padding: 0 0 0 4px;
    letter-spacing: -0.1px;

    .nav-link {
      padding: 7px 4px;
    }
  }
}

.teambarnav,
.timelinebarnav {
  padding-right: 3px;

  .nav-item {
    box-shadow: unset;
  }

  .nav-link {
    border-radius: unset;
    background: unset;
  }

  .nav-link.active {
    border-bottom: 3px solid $brand-color;
  }
}

.abTouch {
  .menubarnav {
  }

  .tabsMoreLeft,
  .tabsMoreRight {
    //    display: none;
  }
}
