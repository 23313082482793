@import "_teaser-match.scss";
// -----------------------------------------------------------------------------
// This file contains styles that are specific to the .sidebar component.
// -----------------------------------------------------------------------------

.events-sidebar-component,
.sidebar-component {
  .today-date,
  .matchSideHeader {
    background-color: $brand-color;
    margin-bottom: 9px;
    color: #fefefe;
    font-weight: 700;
    font-size: 11px;
    line-height: 1;
    overflow: hidden;
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: bottom;
    border-radius: 2px;
    display: inline-block;
    padding: 4px 8px 5px;
  }

  .teaser-match {
    .teaser-match-heading .time,
    .teaser-match-heading .score {
      padding: 6px;
      margin: 0 10px;
    }

    .teaser-match-odds {
      .column:nth-child(3) {
        display: none;
      }
    }

    .teaser-match-content {
      padding: 4px 0;
      margin: 8px;
    }

    .teaser-match-content-editor img {
      width: 28px;
      height: auto;
    }

    .teaser-match-content-text {
      background: #fde9ec;
    }

    .teaser-match-recommendation {
      font-size: 12px;
      text-align: center;
      padding: 3px 3px 0;
    }
  }
}

// Malmö widget styles

.widget__in-iframe {
  width: 100% !important;
  min-height: 100vh; // making content full height of iframe
  display: inline-block !important;
  margin-top: -45px;
  padding-bottom: 40px;
  background-color: #FDD5DC;

  .teaser-match {
    box-shadow: unset;
    border: 1px solid rgba(0, 0, 0, 0.07);

    &:hover {
      border-color: rgba(0, 0, 0, 0.05);
    }
  }

  .events-section {
    // Align with lokala-nyheter/malmo page content
    padding: 16px;

    h2 {
      font-family: arial, sans-serif;
      font-size: 11px;
      font-weight: 700;
      line-height: 16.8px;
      -webkit-font-smoothing: antialiased;
      border-radius: unset;
      display: inline-block;
      margin-bottom: unset;
      vertical-align: unset;
      padding: 5px 5px 4px;
      
      /*
      h2 when iframe is in aside column
      background: #dd2a30;
      font-size: 12px;
      line-height: 12px;
      height: unset;
      padding: 4px 6px;
      */
    }

    .teaser-match:not(.reduce-margin),
    &:not(:first-of-type) {
      margin-top: 1.2rem;
    }
  }

  .btn-show-more {
    transition: all 0.15s ease;
    cursor: pointer;
    margin-top: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    color: $text-color;
    font-weight: bold;
    font-size: 12px;
    padding: 12px;
    display: block;
    text-align: center;
    text-decoration: none;
    position: fixed;
    bottom: 0;
    width: 100%;

    &:hover {
      color: #444;
    }
  }
}
