// -----------------------------------------------------------------------------
// This file contains styles that are specific to article pages.
// -----------------------------------------------------------------------------
// ArticlePageOdds
article.odds {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .teaser-match-heading {
    padding-top: 7px;
    background: #feebee;

    &:before {
      left: 0;
      right: 0;

      @media only screen and (min-width: map-get($breakpoints, medium)) {
        left: 0;
        right: 0;
      }  
    }

    .team {
      font-size: 14px;
    }

    .team-name {
      margin-top: 2px;
    }
  }

  .teaser-match-content {
    flex-wrap: wrap;
    padding: 0;

    .teaser-match-content-text {
      background: unset;
      box-shadow: unset;
      flex: 1 0 100%;
      font-family: helvetica, arial, sans-serif;
      font-style: italic;
      margin: unset;
      padding: 20px 20px 0;
      font-size: 0.8rem;
      line-height: 1.3rem;

      p:not(:last-child) {
        margin-bottom: 1.25rem;
      }
    }
  }

  .teaser-match-content-editor {
    position: relative;
    margin-top: unset;
    width: 100px;
    min-height: 80px;
    text-align: center;

    .icon {
      position: absolute;
      top: 40px;
      left: 25px;
    }
  }

  .article-img {
    position: relative;

    img {
      width: 100%;
      height: 320px;
      display: block;
    }

    .like,
    .venue,
    .time {
      position: absolute;
      color: white;
      font-size: 0.8rem;
    }

    .like {
      top: 10px;
      left: 15px;
    }

    .venue {
      bottom: 10px;
      left: 15px;
    }

    .time {
      bottom: 10px;
      right: 15px;
    }
  }

  .teaser-match-odds {
    margin: 0;
  }

  .teaser-match-content-editor-tips {
    margin-top: 8px;
    width: calc(100% - 116px);
    font-size: 0.8rem;
    line-height: 1.5rem;

    li {
      line-height: 1.2em;
      font-style: italic;
      margin-bottom: 6px;

      &.icon-rhombus:before {
        top: 0.55em;
      }

      span {
        font-family: "arial narrow", Arial, Helvetica, sans-serif;
      }

      span:first-of-type {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }

  .abBtn.abBtnShare {
    padding: 8px 7px 5px;

    .abBtnIcon {
      font-size: 0.8rem;
      line-height: 0.8rem;
    }
  }

  .share-panel {
    margin: 15px 0 0 15px;
  }
}

.article-affiliate-ad-container {
  padding: 10px;
  margin-right: -10px;
  margin-left: -10px;

  &::before {
    top: -1px;
    margin-left: 10px;
  }

  .gdprAdTransparancyCogWheelButton {
    position: absolute;
    height: 15px;
    width: 15px;
    top: -3px;
    right: 5px;
    background-repeat: no-repeat;
  }
}

.article-affiliate-ad {
  position: relative;
  border-top: 2px solid $brand-color;
  border-bottom: 2px solid $brand-color;
  margin-bottom: 2rem;
  font-size: 12px;
  color: #00264f;

  a,
  a:hover,
  a:active {
    color: #00264f;
    text-decoration: underline;
  }

  .teaser-match-odds-heading {
    font-weight: bold;
  }

  &-heading {
    background: #f5f1f1;
  }

  .teaser-match-odds {
    text-transform: unset;
    margin: unset;
    font-size: inherit;
    padding: 8px 5%;
    background: #fcecef;
    border-top: 1px solid #fef3f4;

    .show-more {
      color: #4a4a4a;
      margin-top: 1rem;
      text-decoration: none;
    }
  }
}

.match-stats {
  // reset (#todo remove when done)
  .nav-tabs {
    background-color: unset;
    border-bottom: unset;
  } // end reset

  .tab-content {
    background: white;

    .teaser-match-heading {
      margin: 0 4px;
    }
  }


  &-content {
    margin: 0 4px;
    padding-top: 3px;
    background: white;
  }

  &-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    font-size: 14px;
    min-height: 70px;
  }

  &-info {
    font-family: "helvetica neue", helvetica;
    padding: 1px 8px;
    color: #00264f;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-style: italic;
    background: $section-color;

    .league {
      float: left;
    }

    .time {
      float: right;
    }
  }
}

.tab-content .teaser-match-heading:after {
  display: none;
}

.tab-pane.active {
  padding-bottom: 1.5em;
//  border-bottom: 1px solid rgba(0,0,0,.16);
//  https://app.asana.com/0/853083650676220/1100886965046881
}



@media only screen and (max-width: 589px) {
  article.odds {
    margin: 0 -16px;
  }
}

@media only screen and (max-width: 767px) {
  .team-name {
    font-size: 15px;
  }
}

