.search-loader-img {
  width: 20px;
  position: absolute;
  left: 41%;
  top: 29px;
  z-index: 1001;
}

.sb-search {
  border: none;
  background-image: none;
  background-color: initial;
  box-shadow: none;
  box-sizing: border-box !important;
  width: 100%;
  padding: 10px 15px;
  background: #fff;
  border-radius: 50px;
  margin-bottom: 4px;
  display: block;
  height: calc(2.25rem + 2px);
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline-offset: -2px;
  -webkit-appearance: none;

  &:not(:focus) + .select-search-box__select:not(:hover) {
    display: none !important;
  }

  & + .select-search-box__select {
    display: none;
    max-height: 85vh;
    background: #fff;
    border-radius: 4px;
    overflow: auto;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    z-index: 100;
    min-height: 49px;
    padding: 10px;

    @media only screen and (max-width: 425px) {
      left: 10%;
      width: 90%;
      right: 0;
    }
    @at-root .abTouch .select-search-box__select {
        padding-bottom: 3em;
    }

    .select-search-box__group-header {
      background: #fff;
      padding: 0 10px;
      color: #222;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
      margin-top: 10px;
      border-bottom: 1px solid #e4cfd2;
    }

    .search-item {
      font-size: 16px;
      font-weight: 400;
      list-style: none;
      background-color: #fff;
      display: block;
      min-width: 150px;
      display: flex;

      a {
        border-top: 1px solid #eee;
        padding: 7px 10px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 50px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        flex: 1;
        text-decoration: none;
      }

      b {
        font-size: 18px;
      }
    }

    &--display {
      display: block;
      position: absolute;
    }
  }
}
