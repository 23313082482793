.lineups {
  font-size: 12px;
  padding: 10px 16px;
  width: 100%;

  .heading {
    width: 100%;
    padding: 5px 0;
    min-height: 1px;
    margin-top: 1rem;

    img {
        float: left; 
        margin-right: 1rem;
    }

    &-lineup,
    &-formation {
      margin: 0;
    }
  }

  .players {
    width: 100%;
    border-top: 1px solid #e4cfd2;
    &:last-of-type {
      border-top: none;
    }

    .lineupmembers {
      display: flex;
      border-bottom: 1px solid #e4cfd2;
      padding: 6px 0 1px;

      .jersey-num {
        position: relative;
        width: 1.6em;
        text-align: center;

        &:before {
          content: "";
          background: #ead9db;
          border-radius: 50%;
          height: 1.6em;
          width: 1.6em;
          display: block;
          position: absolute;
          top: -2px;
          left: 0;
          z-index: -1;
        }
      }

      .country-flag {
        width: 10%;
        text-align: center;
      }

      .player-name {
        width: 80%;
      }
    }

    .team-manager {
      display: flex;

      &-img {
        width: 10%;
      }

      &-name {
        width: 50%;
      }
    }
  }
}