@import "../abstracts/variables";
// -----------------------------------------------------------------------------
// This file contains all styles related to the main layout.
// -----------------------------------------------------------------------------
body .ab-theme-gradient-page {
  max-width: $max-width; // Do not remove or change this value.
  margin: 0 auto;
  min-height: calc(100vh - 180px);
}

aside {
  display: none;
}

@media only screen and (min-width: map-get($breakpoints, xsmall)) {
  main,
  .header .main {
    position: relative;
    width: 100%;
  }
}

@media only screen and (min-width: map-get($breakpoints, small)) {
  main {
    & > div {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

@media only screen and (min-width: map-get($breakpoints, medium)) {
  header .main {
    width: 68%;
  }

  main {
    width: 68%;
  }

  aside {
    display: block;
    width: 32%;
//    padding-right: 16px;  KA: What is this for? Removed to make more space for the Stryktipset widget.
  }
}

@media only screen and (min-width: map-get($breakpoints, medium-large)) {
  main {
    width: calc(100% - 300px);
  }
}

@media only screen and (min-width: map-get($breakpoints, large)) {
  nav.navbar,
  .ab-theme-gradient-page {
    display: grid;
    grid-template-columns: minmax(0, auto) 266px;
  }

  .header .main {
    width: calc(68% - 3.5rem);
  }

  main {
    width: 68%;

    & > div {
      padding: 0 32px 16px 16px;
    }
  }

  aside,
  .header .aside {
    width: 32%;
  }
}