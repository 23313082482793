// Standard button styles

button,
.btn,
.button {
  cursor: pointer;

  &.disabled,
  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
  &:hover {
    opacity: 0.95;
  }
  &.link {
    background: transparent;
    text-decoration: underline;
    color: inherit;
  }
}

// Switch button, based on https://codepen.io/sajran/pen/dMKvpb

// Variables
$bg-disabled-color: rgba(0, 0, 0, 0.26);
$bg-enabled-color: rgba(51, 152, 219, 0.5);
$lever-disabled-color: #fff;
$lever-enabled-color: #3398db;
$clickable-area-height: 21px;

.switch {
  display: inline-block;
  position: relative;
  margin-top: 1px;
  font-size: 16px;
  line-height: 24px;

  &__input {
    position: absolute;
    top: -3px;
    left: 0;
    width: 40px;
    margin: 0;
    height: $clickable-area-height;
    opacity: 0;
    z-index: 0;
  }

  // Unchecked
  &__label {
    display: flex;
    align-items: center;
    padding: 0 0 0 44px;
    cursor: pointer;
    color: white;
    font-size: 12px;
    line-height: 1em;
    height: $clickable-area-height;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      width: 36px;
      height: 14px;
      background-color: $bg-disabled-color;
      border-radius: 14px;
      z-index: 1;
      transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      width: 20px;
      height: 20px;
      background-color: $lever-disabled-color;
      border-radius: 14px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      z-index: 2;
      transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      transition-property: left, background-color;
    }
  }

  // Checked
  &__input:checked + &__label {
    &:before {
      background-color: $bg-enabled-color;
    }

    &:after {
      left: 16px;
      background-color: $lever-enabled-color;
    }
  }

  &.right {
    .switch__input {
      left: unset;
      right: 0;
    }
    // Unchecked
    .switch__label {
      padding: 0 44px 0 0;
      &:before {
        left: auto;
        right: 0;
      }
      &:after {
        left: auto;
        right: 0;
        transition-property: right, background-color;
      }
    }
    .switch__input:checked + .switch__label {
      &:after {
        right: 16px;
      }
    }
  }
}
