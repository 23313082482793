// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


/// Regular font family
/// @type List
$text-font-stack: 'Arial', 'Helvetica', 'Verdana', 'Geneva', sans-serif !default;



/// Brand main background color
/// @type Color
$brand-bg-color: #fde3e6 !default;

/// Copy text color
/// @type Color
$text-color: rgba(34, 34, 34, 1) !default;

/// Main brand color
/// @type Color
$brand-color: #a11014 !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Blue link, .abTagLink
/// @type Color
$blue-link: #006ba8 !default;





/// Container's maximum width
/// @type Length
$max-width: 1294px !default;





/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'xsmall':320px,
  'small': 590px,
  'medium': 768px,
  'medium-large':950px,
  'large': 1180px,
  'HD':1366px,
  'FullHD':1920px
) !default;





/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;



/// Cortex Craft variables
$primary-color:white;
$section-color: #FDD5DB;
$malservicenav-color: $brand-color;

$minWidth590px: 590px;
$minWidth768px: 768px;
$minWidth1180px: 1180px;


