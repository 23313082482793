// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------


// Mockup styles

footer {
  padding: 16px;
  margin: 2rem 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  border-top: 1px solid rgba(0, 0, 0, .08);

  .footer-column {
    margin: 0;
    text-align: left;

    .footer-info {
      width: 100%;
      max-width: 350px;
      margin-right: 100px;
      text-align: left;
      line-height: 1.7;
      font-size: 12px;

      p a {
        color: black;
      }
    }

    .footer-privacy-link,
    .footer-cookie-link {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      color: $blue-link;
      font-weight: 700;
    }

    .footer-staff {
      font-size: 14px;
      display: flex;
      line-height: 1.7;
      flex-direction: column;
      padding-top: 0;
      flex-wrap: wrap;
      border-top: none;
    }

    .footer-copyright {
      font-size: 14px;
      margin-top: 5px;
      text-align: left;
    }
  }

  .footer-content-bolded {
    font-weight: bold;
  }

  @media only screen and (max-width: 589px) {
    display: block;

    .footer-column {
      margin-bottom: 1rem;
      text-align: center;

      &:last-child {
        padding-top: 1rem;
        border-top: 1px solid rgba(0, 0, 0, .08);
      }

      .footer-info,
      .footer-copyright {
        text-align: center;
      }
    }
  }
}

.mobileNavbar {
  @media only screen and (max-width: 767px) {
    z-index: 100;
    display: block;
    overflow: hidden;
    background-color: #333;
    position: fixed;
    bottom: 0;
    width: 100%;

    .aside {
      display: flex;
      list-style: none;
      justify-content: center;
    }

    .dropdown-menu {
      display: none;
    }

    .nav-item .nav-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      text-align: -webkit-center;
      line-height: 15px;
      font-size: 11px;
      color: #fff;
      min-width: 60px;
      padding: .5rem 0 .2rem;

      svg {
        font-size: 2em;
      }

      span {
        line-height: 2.2em;
      }

      &:hover,
      &:focus {
        color: white;
      }
    }
  }
}