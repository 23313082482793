@import "../abstracts/variables";
// -----------------------------------------------------------------------------
// This file contains all temporary or not-best-practice code.
// -----------------------------------------------------------------------------

// A bunch of resets
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
button,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: initial;
}

body {
  background-color: $brand-bg-color;
  min-height: 100vh;
  background-image: linear-gradient(180deg, $brand-bg-color, $section-color 300px);
}

#root {
  position: relative;
  padding-top: 44px;
}

// Hiding incomplete stuff that should not display in production

nav.navbar .aside li,
nav.navbar .navbar-nav-user .nav-item,
.navbar-toggler svg,
.article-image-credits,
.team-names,
.icon-heart,
.mobileNavbar {
  display: none !important;
}

// Start override styles for stryktipset widget pages

@media only screen and (max-width: 767px) {
  main + aside {
    display: block;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: unset;

    > * {
      display: none;
    }

    .tipset-widget {
      display: block;
      position: absolute;
      width: 100%;
      position: fixed;
      top: unset;
      bottom: 0;
      width: 100%;

      .tipset-header,
      .tipset-footer,
      &-header,
      &-footer {
        position: unset;
      }
      .tipset-footer {
        padding: 16px 12px 0;

        // Iphone X specific fix for bottom area
        border-bottom: constant(safe-area-inset-bottom) solid black; /* iOS 11.0 */
        border-bottom: env(safe-area-inset-bottom) solid black; /* iOS 11.2 */
        
        &-button {
          font-weight: bold;
          padding: 12px 1em;
          font-size: 15px;
          margin-bottom: 18px;
        }
        .switch {
          margin: -10px 0 -10px -12px;

          &__label {
            padding: 20px 44px 20px 12px;
            height: auto;
            font-weight: bold;
            font-size: 16px;

            &:before {
              top: 21px;
            }

            &:after {
              top: 18px;
            }
          }
        }
      }

      &-body {
        display: none;
      }

      &.open {
        overflow-x: hidden;
        overflow-y: scroll;
        height: calc(100vh - 44px);
        -webkit-overflow-scrolling: touch;
        top: 0;
        margin-top: unset;
        padding: 44px 0 80px;
        height: calc(100vh); // Adding 124px for ios safari controls

        .tipset-header {
          position: sticky;
          top: 0;
        }
        .tipset-footer {
          position: fixed;
          bottom: 0;
          width: 100%;
        }
        .tipset-widget-body {
          display: block;
          pointer-events: auto;
          padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
          padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
        }
      }
    }
  }
  footer.footer {
    padding-bottom: 111px;
  }
  body {
    //  position: fixed;
    //  overflow: hidden;
    width: 100%;
    height: 100%;
  }

  main.tipset-widget-open {
    display: none;
    pointer-events: none;
  }
}

// End override styles for stryktipset widget pages
