@import "../abstracts/variables";

.points-table {
  padding: 10px 16px;
  font-size: 11px;

  &-head {
    font-weight: bold;
  }

  .points-table-head,
  .points-table-body {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 0;
  }

  &-body {
    border-top: 1px solid #e4cfd2;
    &:last-child {
      border-bottom: 1px solid #e4cfd2;
    }
  }

  .points-table-title,
  .team-status {
    flex: 1 1 0;
    width: 0;
    text-align: center;
    margin: 0 4px;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(7),
    &:nth-child(8) {
      flex: 0 1 auto;
    }

    &:nth-child(1) {
      width: 1.6em;
    }

    &:nth-child(2) {
      min-width: 30%;
      text-align: unset;
    }

    &:nth-child(7) {
      white-space: nowrap;
      min-width: 15%;
    }

    &:last-child {
      margin-right: unset;
      width: 1.8em;
    }
  }

  .table-rank {
    position: relative;
    text-align: center;

    &:before {
      content: "";
      background: #ead9db;
      border-radius: 50%;
      height: 1.6em;
      width: 1.6em;
      display: block;
      position: absolute;
      top: -2px;
      left: 0;
      z-index: -1;
    }
  }
}

