.icon {
  position: relative;
  padding-left: 15px;

  &-rhombus {
    &:before {
      content: "";
      height: 11px;
      width: 11px;
      display: block;
      left: 0;
      position: absolute;
      top: 50%;
      transform: skew(-8deg) translateY(-55%);
    }

    &__blue:before {
      background: #174886;
    }
  }

  &-dot {
    &:before {
      border-radius: 50%;
      content: "";
      display: block;
      height: 13px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-55%);
      width: 13px;
      border: 1px solid white;
    }

    &__blue:before {
      background: #174886;
    }
  }

  /* #todo 
Here's a placeholder heart! 
Should be replaced with a proper icon.
*/

  &-heart:before {
    content: "\2764";
  }

  &-white:before {
    color: white;
  }

  &-chevron::before {
    border-style: solid;
    border-width: 0.1em 0.1em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    width: 0.45em;
    top: 0.5em;
    left: 0.15em;
    position: relative;
    transform: rotate(-45deg);
    vertical-align: top;
  }

  &-chevron.right:before {
    left: 0;
    transform: rotate(45deg);
  }

  &-chevron.bottom:before {
    top: 0;
    transform: rotate(135deg);
  }

  &-chevron.left:before {
    left: 0.25em;
    transform: rotate(-135deg);
  }
}
  