.report-items-list {
  padding: 10px 0;
  list-style: none;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  background: white;

  .list-item-pause {
    margin-bottom: 8px;
    border-bottom: 8px solid $section-color;
  }

  .report-item {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    line-height: 20px;
    padding: 0 16px;

    &.report-item-scoreChange {
      background-color: #e8eae4;

      &.report-item-ice-hockey {
        background-color: #fdf3f5;

      }
    }

    .goal-section-small {
      width: 40px;
      margin: 4px 0 0 8px;
      height: 25px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 1px dashed #222;
      border-bottom: 3px solid #222;
      position: relative;
      -ms-grid-row-align: center;

      .goal-section-marker {
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: #dd2a30;

        &.goal-section-marker-1 {
          left: 5px;
          bottom: 5px;
        }

        &.goal-section-marker-2 {
          left: 18px;
          bottom: 5px;
        }

        &.goal-section-marker-3 {
          right: 5px;
          bottom: 5px;
        }

        &.goal-section-marker-4 {
          left: 5px;
          bottom: 11px;
        }

        &.goal-section-marker-5 {
          left: 18px;
          bottom: 11px;
        }

        &.goal-section-marker-51 {
          left: 13px;
          bottom: 11px;
        }

        &.goal-section-marker-52 {
          left: 23px;
          bottom: 11px;
        }

        &.goal-section-marker-6 {
          right: 5px;
          bottom: 11px;
        }

        &.goal-section-marker-7 {
          left: 10px;
          top: 5px;
        }

        &.goal-section-marker-9 {
          right: 10px;
          top: 5px;
        }
      }
    }

    .goal-section-large {
      width: 60px;
      margin-left: 8px;
      height: 35px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border: 1px dashed #222;
      border-bottom: 4px solid #222;
      position: relative;

      .goal-section-marker {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #dd2a30;

        &.goal-section-marker-1 {
          left: 5px;
          bottom: 5px;
        }

        &.goal-section-marker-2 {
          left: 25px;
          bottom: 5px;
        }

        &.goal-section-marker-3 {
          right: 5px;
          bottom: 5px;
        }

        &.goal-section-marker-4 {
          left: 5px;
          bottom: 13px;
        }

        &.goal-section-marker-5 {
          left: 25px;
          bottom: 13px;
        }

        &.goal-section-marker-51 {
          left: 20px;
          bottom: 13px;
        }

        &.goal-section-marker-52 {
          left: 30px;
          bottom: 13px;
        }

        &.goal-section-marker-6 {
          right: 5px;
          bottom: 13px;
        }

        &.goal-section-marker-7 {
          left: 15px;
          top: 5px;
        }

        &.goal-section-marker-9 {
          right: 15px;
          top: 5px;
        }
      }
    }

    .report-item-body {
      flex-grow: 1;
      margin: 10px 10px 10px 0;

      .report-item-shot-content {
        display: flex;
        align-items: flex-start;

        .report-item-shot-info {
          flex-grow: 1;
        }
      }

      .report-item-scorechange-content {
        display: flex;

        .report-item-scorechange-info {
          flex-grow: 1;
        }
      }

      .report-item-header {
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 4px;
      }

      .report-item-comment {
        opacity: .75;
      }

      .report-item-time {
        padding: 16px 16px 0;
        font-size: 12px;
        text-align: right;
        white-space: nowrap;
        flex-shrink: 0;
      }
    }
  }

  .report-items-list-item:first-child {
    .report-item-type {
      //            padding-top: 0;
      //            margin-top: 8px;
    }
  }

  .report-item-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    margin-top: 10px;

    .report-item-type-dot {
      width: 100%;
      position: relative;
      line-height: 1rem;
      text-align: center;

      &:after {
        width: 5px;
        height: 5px;
        display: inline-block;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, .16);
        content: "";
      }
    }

    &:before {
      top: 1rem;
      height: 100%;
      content: "";
      display: block;
      position: absolute;
      border-left: 1px solid #e2e4e6;
      left: 15px;
    }

    .report-item-type-icon {
      position: relative;

      .report-icon {
        width: 30px;
        height: auto;
        display: block;
      }
    }
  }

  @media (min-width: 425px) {
    .report-item {
      .report-item-type {
        //                width: 36px;
        //                padding-right: 16px;
      }
    }
  }
}

.report-items-list-item {
  .report-item {
    &-type {
      width: 30px;
      margin-right: 10px;
    }

    &-body {
      //            padding: 16px 0;
      //            flex-grow: 1;
    }

    &-time {
      padding: 10px 0;
      flex-shrink: 0;
      text-align: right;
    }
  }
}