@import "../abstracts/variables";

.mal-past-events {
  width: 100%;

  .past-evnt-headerdiv {
    display: flex;
    padding: 10px;
    background: $section-color;
    font-family: Helvetica;
    font-style: italic;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    justify-content: space-between;
  }

  .past-evnt-content {
    justify-content: center;
    display: flex;
    flex: 0 0 4%;
    cursor: pointer;
    padding: 10px;
    align-items: center;
    color: #222222;

    .participant {
      font-weight: bold;
      line-height: 1.5rem;
    }

    .past-team-score {
      padding: 8px 12px;
      background: #766f6f;
      color: white;
      font-size: 10px;
      margin: 0 20px;
    }
  }

  .mal-event-heading {
    text-align: center;

    &-text {
      width: 100%;
      text-align: center;
      font-size: 22px;
      font-weight: 700;
      border-bottom: 1px solid gre;
    }
  }

  .teaser-match-heading {
    &:before {
      display: none;
    }
  }
}