// -----------------------------------------------------------------------------
// This file contains styles that are specific to the .teaser-match component.
// -----------------------------------------------------------------------------
.teaser-match {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  background-color: #feebee;
  padding: 7px 16px;
  box-shadow: 0 0px 2px 1px rgba(0,0,0,0.07);
  border-bottom: 1px solid rgba(0,0,0,.15);
  border-radius: 1px;
  transition: all 0.15s ease;
  text-decoration: none;
  color: $text-color;

  .game-spel-tips {
    margin-top: 4px;
    text-align: center;
    font-size: 13px;

    &:first-child {
      margin-top: 4px;
    }
  }

  a:hover {
    text-decoration: none;
  }

  &-number {
    position: absolute;
    top: 0;
    left: 0;
    font-family: verdana, helvetica, sans-serif;
    font-size: 21px;
    font-weight: 700;
    color: #766f6f;
  }
  /* #todo 
   .heading should be a separate component with a different name. 
   But what? 
  */
  .heading {
    font-family: Tahoma, Arial, sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 1rem;
    position: absolute;
    top: -16px;
    left: 16px;
    background: black;
    color: white;
    transform: skew(-10deg);
    border-radius: 8px 0 8px 0px;
    padding: 3px 12px;
    letter-spacing: 0.4px;
  }

  &-odds,
  &-bet {
    display: flex;
    flex: 0 0 5%;
  }

  .abIconArrowConvexDown.right:before {
    display: inline-block;
    transform: rotate(0deg);
  }

  &:hover,
  &:active {
    box-shadow: 0 0px 3px 1px rgba(0,0,0,.15);
    opacity: 0.9;
    text-decoration: none;
  }
}

.teaser-match-odds {
  margin: 0 -16px 1px;
  padding: 6px 18%;
  background: #e4cfd2;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1em;
  display: flex;

  .column {
    display: flex;

    &:nth-child(1),
    &:nth-child(3) {
      flex: 0 0 20%;
    }

    &:nth-child(2) {
      flex: 0 0 60%;
    }

    div {
      flex: 1 0;
      text-align: center;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-number {
    position: absolute;
    left: 16px;
    font-family: verdana, helvetica, sans-serif;
    font-size: 21px;
    font-weight: bold;
    color: #766f6f;
  }
}

.teaser-match-heading {
  position: relative;
  justify-content: center;
  display: flex;
  padding: 0 10px 7px;
  align-items: center;
  color: $text-color;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    bottom: 0;
    left: -8px;
    right: -8px;
    height: 1px;
    background: rgba(34, 34, 34, .1);

    @media only screen and (min-width: map-get($breakpoints, medium)) {
      left: -16px;
      right: -16px;
    }
  }

  .time,
  .score {
    padding: 8px 12px;
    background: #766f6f;
    color: white;
    font-size: 10px;
    margin: 0 20px;
  }

  .team {
    display: flex;
    align-items: center;
    flex: 2 0;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;

    &-left {
      justify-content: flex-end;

      .team-name {
        padding-right: 0;
        text-align: right;
      }
    }
  }

  .team-logo {
    max-height: 32px;
    width: auto;

    &__left {
      margin-right: 6px;
    }

    &__right {
      margin-left: 6px;
    }
  }

  .teaser-match-link {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.1em;
  }

  .team-name {
    position: relative;
    padding-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
  }
}

.teaser-match-content {
  margin-top: 14px;
  padding: 8px 0;
  display: flex;
}

.event-grades + .teaser-match-content {
  margin-top: 0;
}

.teaser-match-content-editor {
  margin-top: -15px;

  img {
    width: 50px;
    height: auto;
    clip-path: circle(49.4% at center);
  }

  &-name {
    margin-top: 4px;
    font-size: 9px;
    text-transform: uppercase;
  }
}

.teaser-match-content-text {
  padding: 10px 13px;
  margin: 0 0 0 12px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0 9px 9px 9px;
  width: 100%;
  font-size: 16px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.02);

  p:not(:last-child) {
    margin-bottom: 1rem;
  }
}

aside .abTheme-sportbladet.abThemeBgTeaser.teaser-match {
  background-color: inherit;
}

aside .teaser-match {
  background-color: inherit;
  padding: 0;

  &-number {
    letter-spacing: -2px;
    top: 0.45em;
    left: 4px;
    width: 1.2em;
    text-align: center;
    font-size: 18px;
    line-height: 23px;
  }

  &.reduce-margin {
    margin-top: 5px;
  }

  .team {
    font-size: 12px;
    line-height: 1em;
  }

  .teaser-match-heading {
    background: #feebee;
    padding: 8px 20px;
    margin: 0;

    &:before {
      left: 0;
      right: 0;
    }

    .time,
    .score {
      padding: 6px;
      margin: 0 7px;
    }
  }

  .team-logo {
    max-height: 20px;
    width: auto;

    &__left {
      margin-right: 4px;
    }

    &__right {
      margin-left: 4px;
    }
  }

  .teaser-match-link {
    right: 9px;
    top: 0.52rem;
    font-size: 0.9em;
  }

  .heading {
    z-index: 2;
    top: -14px;
    left: 15px;
    font-size: 11px;
    line-height: 14px;
  }

  .teaser-match-odds {
    padding: 5px 8%;
    margin: 0 0 1px;

    .column:nth-child(3) {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .teaser-match-content {
    padding: 4px 0;
    margin: 8px;
  }

  .teaser-match-content-editor {
    margin-top: -8px;

    img {
      width: 28px;
      height: auto;
    }
  }

  .teaser-match-content-text {
    font-size: 12px;
    margin-left: 2px;
    padding: 5px 8px;
  }

  .teaser-match-recommendation {
    font-size: 12px;
    text-align: center;
    padding: 3px 3px 0;
  }

  a {
    text-decoration: none;
  }
}

@media only screen and (max-width: 589px) {
  .tab-content .teaser-match {
    &-heading {
      padding: 0 20px 9px;
    }

    &-odds {
      margin: 0 -8px 1px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .tab-content .teaser-match {
    &-number {
      letter-spacing: -1px;
    }
  }

  .teaser-match {
    padding: 8px;

    &-number {
      font-size: 17px;
      top: 0;
      left: -3px;
    }

    .team-name {
      font-size: 15px;
    }

    .team-logo {
      max-height: 24px;
    }

    &-heading {
      padding-bottom: 9px;

      .time,
      .score {
        padding: 5px 8px;
        margin: 0 7px;
      }

      .teaser-match-link {
        top: -4px;
        right: 2px;
      }
    }

    .event-grades {
      .game-suggestions {
        margin: 0 10px;

        .game-suggestion {
          padding: 5px 0;
        }
      }

      .grade-img {
        height: 15px;
      }
    }

    &-content-text {
      margin-top: 12px;
      margin-left: 4px;
      font-size: 15px;
      padding: 6px 9px;
    }

    &-odds {
      padding: 6px 10%;
      margin: 0 -8px 1px;
    }
  }
}


