@import "../abstracts/variables";
@import "../components/search";
// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.header {
  background-color: $brand-bg-color;
  height: 44px;
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  animation: Ub3uE .5s ease-in-out;
  transform: translateZ(0);
  border-bottom: 4px solid $brand-color;

  .navbar-collapse {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
}

nav.navbar {
  position: relative;
  height: 40px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1294px;
  margin: 0 auto;

  .sb-search {
    height: 30px;
    padding: 4px 15px;
    margin-bottom: 0;
  }

  .navbar-brand {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    display: flex;
    height: 44px;
    padding: 0 8px 0 16px;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
    text-decoration: none;
    align-items: center;

    img {
      width: 56px;
      height: auto;
    }
    &:hover {
      cursor: pointer;
    }
    &:active {
      background: unset;
    }
  }

  .navbar-nav {
    display: flex;

    &.aside,
    &-user .nav-item {
      display: none;

      @media only screen and (min-width: map-get($breakpoints, medium)) {
        display: flex;
      }
    }

    &.main .nav-item {
      flex: 1 1 auto;
      padding: 0 5px 0 80px;

      @media only screen and (min-width: map-get($breakpoints, small)) {
        padding: 0 24px 0 90px;
      }

      @media only screen and (min-width: map-get($breakpoints, medium)) {
        padding: 0 24px 0 110px;
      }
    }
    align-items: center;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .dropdown,
    .dropleft,
    .dropright,
    .dropup {
      position: relative;

      .dropdown-menu-right {
        right: 0;
        left: auto;
      }

      .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 10rem;
        padding: .5rem 0;
        margin: .125rem 0 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: .25rem;

        @media only screen and (min-width: map-get($breakpoints, medium)) {
          position: absolute;
          float: none;
        }
      }
    }

    .nav-item .nav-link {
      line-height: 15px;
      font-size: 11px;
      color: #fff;
      display: flex;
      padding: .5rem 0.6rem;

      svg {
        font-size: 2em;
      }

      span {
        padding-left: 5px;
        line-height: 2.2em;
      }

      &:hover,
      &:focus {
        color: white;
      }

      @media only screen and (min-width: map-get($breakpoints, medium)) {
        padding-right: 8px;
        padding-left: 8px;
      }
    }

    &-user {
      margin-left: auto;
      font-size: 1rem;
      justify-content: flex-end;
      margin-right: 4px;

      @media only screen and (min-width: map-get($breakpoints, medium)) {
        margin: 0 1rem;
      }

      @media only screen and (min-width: map-get($breakpoints, large)) {
        width: 266px;
      }
    }
  }

  .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1);
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: initial;
    border: 1px solid transparent;
    border-radius: .25rem;

    @media only screen and (min-width: map-get($breakpoints, medium)) {
      display: none;
    }

    .navbar-toggler-icon {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      vertical-align: middle;
      content: "";
      background: no-repeat 50%;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 30 30' xmlns='http://ww…p='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }

  .search-loader-img {
    width: 20px;
    position: absolute;
    right: 36px;
    top: 8px;
  }
}

