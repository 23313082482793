@charset "UTF-8";
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
button,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: initial; }

body {
  background-color: #fde3e6;
  min-height: 100vh;
  background-image: linear-gradient(180deg, #fde3e6, #FDD5DB 300px); }

#root {
  position: relative;
  padding-top: 44px; }

nav.navbar .aside li,
nav.navbar .navbar-nav-user .nav-item,
.navbar-toggler svg,
.article-image-credits,
.team-names,
.icon-heart,
.mobileNavbar {
  display: none !important; }

@media only screen and (max-width: 767px) {
  main + aside {
    display: block;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: unset; }
    main + aside > * {
      display: none; }
    main + aside .tipset-widget {
      display: block;
      position: absolute;
      width: 100%;
      position: fixed;
      top: unset;
      bottom: 0;
      width: 100%; }
      main + aside .tipset-widget .tipset-header,
      main + aside .tipset-widget .tipset-footer, main + aside .tipset-widget-header, main + aside .tipset-widget-footer {
        position: unset; }
      main + aside .tipset-widget .tipset-footer {
        padding: 16px 12px 0;
        border-bottom: constant(safe-area-inset-bottom) solid black;
        /* iOS 11.0 */
        border-bottom: env(safe-area-inset-bottom) solid black;
        /* iOS 11.2 */ }
        main + aside .tipset-widget .tipset-footer-button {
          font-weight: bold;
          padding: 12px 1em;
          font-size: 15px;
          margin-bottom: 18px; }
        main + aside .tipset-widget .tipset-footer .switch {
          margin: -10px 0 -10px -12px; }
          main + aside .tipset-widget .tipset-footer .switch__label {
            padding: 20px 44px 20px 12px;
            height: auto;
            font-weight: bold;
            font-size: 16px; }
            main + aside .tipset-widget .tipset-footer .switch__label:before {
              top: 21px; }
            main + aside .tipset-widget .tipset-footer .switch__label:after {
              top: 18px; }
      main + aside .tipset-widget-body {
        display: none; }
      main + aside .tipset-widget.open {
        overflow-x: hidden;
        overflow-y: scroll;
        height: calc(100vh - 44px);
        -webkit-overflow-scrolling: touch;
        top: 0;
        margin-top: unset;
        padding: 44px 0 80px;
        height: calc(100vh); }
        main + aside .tipset-widget.open .tipset-header {
          position: sticky;
          top: 0; }
        main + aside .tipset-widget.open .tipset-footer {
          position: fixed;
          bottom: 0;
          width: 100%; }
        main + aside .tipset-widget.open .tipset-widget-body {
          display: block;
          pointer-events: auto;
          padding-bottom: constant(safe-area-inset-bottom);
          /* iOS 11.0 */
          padding-bottom: env(safe-area-inset-bottom);
          /* iOS 11.2 */ }
  footer.footer {
    padding-bottom: 111px; }
  body {
    width: 100%;
    height: 100%; }
  main.tipset-widget-open {
    display: none;
    pointer-events: none; } }

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box; }

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit; }

/**
 * Basic styles for links
 */
a {
  color: #006ba8; }
  a:active {
    background: rgba(0, 107, 168, 0.15); }
  a:hover, a:active, a:focus {
    color: #222222;
    text-decoration: underline; }

.list {
  list-style: none;
  padding-left: 0; }

/**
 * Basic typography style for copy text
 */
body {
  color: #222222;
  font: normal 125%/1.4 "Arial", "Helvetica", "Verdana", "Geneva", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Verdana,Arial,Helvetica,sans-serif;
  font-weight: 700;
  line-height: 1.1;
  margin: 0; }

h1 {
  font-size: 50px;
  font-size: 1.625rem;
  line-height: 1.08;
  letter-spacing: -.05rem;
  margin-bottom: 8px; }

@media only screen and (min-width: 590px) {
  h1 {
    font-size: 2.8125rem; } }

@media only screen and (min-width: 1180px) {
  h1 {
    font-size: 3.125rem; } }

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table; }

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap; }

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.hide, .hidden {
  display: none !important; }

.font-bold {
  font-weight: 700; }

.uppercase {
  text-transform: uppercase; }

.loader-img-div {
  text-align: center;
  padding: 5px; }
  .loader-img-div .loader-img {
    width: 5%; }

body .ab-theme-gradient-page {
  max-width: 1294px;
  margin: 0 auto;
  min-height: calc(100vh - 180px); }

aside {
  display: none; }

@media only screen and (min-width: 320px) {
  main,
  .header .main {
    position: relative;
    width: 100%; } }

@media only screen and (min-width: 590px) {
  main > div {
    padding-right: 16px;
    padding-left: 16px; } }

@media only screen and (min-width: 768px) {
  header .main {
    width: 68%; }
  main {
    width: 68%; }
  aside {
    display: block;
    width: 32%; } }

@media only screen and (min-width: 950px) {
  main {
    width: calc(100% - 300px); } }

@media only screen and (min-width: 1180px) {
  nav.navbar,
  .ab-theme-gradient-page {
    display: grid;
    grid-template-columns: minmax(0, auto) 266px; }
  .header .main {
    width: calc(68% - 3.5rem); }
  main {
    width: 68%; }
    main > div {
      padding: 0 32px 16px 16px; }
  aside,
  .header .aside {
    width: 32%; } }

.search-loader-img {
  width: 20px;
  position: absolute;
  left: 41%;
  top: 29px;
  z-index: 1001; }

.sb-search {
  border: none;
  background-image: none;
  background-color: initial;
  box-shadow: none;
  box-sizing: border-box !important;
  width: 100%;
  padding: 10px 15px;
  background: #fff;
  border-radius: 50px;
  margin-bottom: 4px;
  display: block;
  height: calc(2.25rem + 2px);
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline-offset: -2px;
  -webkit-appearance: none; }
  .sb-search:not(:focus) + .select-search-box__select:not(:hover) {
    display: none !important; }
  .sb-search + .select-search-box__select {
    display: none;
    max-height: 85vh;
    background: #fff;
    border-radius: 4px;
    overflow: auto;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    z-index: 100;
    min-height: 49px;
    padding: 10px; }
    @media only screen and (max-width: 425px) {
      .sb-search + .select-search-box__select {
        left: 10%;
        width: 90%;
        right: 0; } }
    .abTouch .select-search-box__select {
      padding-bottom: 3em; }
    .sb-search + .select-search-box__select .select-search-box__group-header {
      background: #fff;
      padding: 0 10px;
      color: #222;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
      margin-top: 10px;
      border-bottom: 1px solid #e4cfd2; }
    .sb-search + .select-search-box__select .search-item {
      font-size: 16px;
      font-weight: 400;
      list-style: none;
      background-color: #fff;
      display: block;
      min-width: 150px;
      display: flex; }
      .sb-search + .select-search-box__select .search-item a {
        border-top: 1px solid #eee;
        padding: 7px 10px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 50px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        flex: 1;
        text-decoration: none; }
      .sb-search + .select-search-box__select .search-item b {
        font-size: 18px; }
    .sb-search + .select-search-box__select--display {
      display: block;
      position: absolute; }

.header {
  background-color: #fde3e6;
  height: 44px;
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  animation: Ub3uE .5s ease-in-out;
  transform: translateZ(0);
  border-bottom: 4px solid #a11014; }
  .header .navbar-collapse {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%; }

nav.navbar {
  position: relative;
  height: 40px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1294px;
  margin: 0 auto; }
  nav.navbar .sb-search {
    height: 30px;
    padding: 4px 15px;
    margin-bottom: 0; }
  nav.navbar .navbar-brand {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    display: flex;
    height: 44px;
    padding: 0 8px 0 16px;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
    text-decoration: none;
    align-items: center; }
    nav.navbar .navbar-brand img {
      width: 56px;
      height: auto; }
    nav.navbar .navbar-brand:hover {
      cursor: pointer; }
    nav.navbar .navbar-brand:active {
      background: unset; }
  nav.navbar .navbar-nav {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    nav.navbar .navbar-nav.aside,
    nav.navbar .navbar-nav-user .nav-item {
      display: none; }
      @media only screen and (min-width: 768px) {
        nav.navbar .navbar-nav.aside,
        nav.navbar .navbar-nav-user .nav-item {
          display: flex; } }
    nav.navbar .navbar-nav.main .nav-item {
      flex: 1 1 auto;
      padding: 0 5px 0 80px; }
      @media only screen and (min-width: 590px) {
        nav.navbar .navbar-nav.main .nav-item {
          padding: 0 24px 0 90px; } }
      @media only screen and (min-width: 768px) {
        nav.navbar .navbar-nav.main .nav-item {
          padding: 0 24px 0 110px; } }
    nav.navbar .navbar-nav .dropdown,
    nav.navbar .navbar-nav .dropleft,
    nav.navbar .navbar-nav .dropright,
    nav.navbar .navbar-nav .dropup {
      position: relative; }
      nav.navbar .navbar-nav .dropdown .dropdown-menu-right,
      nav.navbar .navbar-nav .dropleft .dropdown-menu-right,
      nav.navbar .navbar-nav .dropright .dropdown-menu-right,
      nav.navbar .navbar-nav .dropup .dropdown-menu-right {
        right: 0;
        left: auto; }
      nav.navbar .navbar-nav .dropdown .dropdown-menu,
      nav.navbar .navbar-nav .dropleft .dropdown-menu,
      nav.navbar .navbar-nav .dropright .dropdown-menu,
      nav.navbar .navbar-nav .dropup .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 10rem;
        padding: .5rem 0;
        margin: .125rem 0 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: .25rem; }
        @media only screen and (min-width: 768px) {
          nav.navbar .navbar-nav .dropdown .dropdown-menu,
          nav.navbar .navbar-nav .dropleft .dropdown-menu,
          nav.navbar .navbar-nav .dropright .dropdown-menu,
          nav.navbar .navbar-nav .dropup .dropdown-menu {
            position: absolute;
            float: none; } }
    nav.navbar .navbar-nav .nav-item .nav-link {
      line-height: 15px;
      font-size: 11px;
      color: #fff;
      display: flex;
      padding: .5rem 0.6rem; }
      nav.navbar .navbar-nav .nav-item .nav-link svg {
        font-size: 2em; }
      nav.navbar .navbar-nav .nav-item .nav-link span {
        padding-left: 5px;
        line-height: 2.2em; }
      nav.navbar .navbar-nav .nav-item .nav-link:hover, nav.navbar .navbar-nav .nav-item .nav-link:focus {
        color: white; }
      @media only screen and (min-width: 768px) {
        nav.navbar .navbar-nav .nav-item .nav-link {
          padding-right: 8px;
          padding-left: 8px; } }
    nav.navbar .navbar-nav-user {
      margin-left: auto;
      font-size: 1rem;
      justify-content: flex-end;
      margin-right: 4px; }
      @media only screen and (min-width: 768px) {
        nav.navbar .navbar-nav-user {
          margin: 0 1rem; } }
      @media only screen and (min-width: 1180px) {
        nav.navbar .navbar-nav-user {
          width: 266px; } }
  nav.navbar .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: initial;
    border: 1px solid transparent;
    border-radius: .25rem; }
    @media only screen and (min-width: 768px) {
      nav.navbar .navbar-toggler {
        display: none; } }
    nav.navbar .navbar-toggler .navbar-toggler-icon {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      vertical-align: middle;
      content: "";
      background: no-repeat 50%;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 30 30' xmlns='http://ww…p='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
    nav.navbar .navbar-toggler:not(:disabled):not(.disabled) {
      cursor: pointer; }
  nav.navbar .search-loader-img {
    width: 20px;
    position: absolute;
    right: 36px;
    top: 8px; }

footer {
  padding: 16px;
  margin: 2rem 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  border-top: 1px solid rgba(0, 0, 0, 0.08); }
  footer .footer-column {
    margin: 0;
    text-align: left; }
    footer .footer-column .footer-info {
      width: 100%;
      max-width: 350px;
      margin-right: 100px;
      text-align: left;
      line-height: 1.7;
      font-size: 12px; }
      footer .footer-column .footer-info p a {
        color: black; }
    footer .footer-column .footer-privacy-link,
    footer .footer-column .footer-cookie-link {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      color: #006ba8;
      font-weight: 700; }
    footer .footer-column .footer-staff {
      font-size: 14px;
      display: flex;
      line-height: 1.7;
      flex-direction: column;
      padding-top: 0;
      flex-wrap: wrap;
      border-top: none; }
    footer .footer-column .footer-copyright {
      font-size: 14px;
      margin-top: 5px;
      text-align: left; }
  footer .footer-content-bolded {
    font-weight: bold; }
  @media only screen and (max-width: 589px) {
    footer {
      display: block; }
      footer .footer-column {
        margin-bottom: 1rem;
        text-align: center; }
        footer .footer-column:last-child {
          padding-top: 1rem;
          border-top: 1px solid rgba(0, 0, 0, 0.08); }
        footer .footer-column .footer-info,
        footer .footer-column .footer-copyright {
          text-align: center; } }

@media only screen and (max-width: 767px) {
  .mobileNavbar {
    z-index: 100;
    display: block;
    overflow: hidden;
    background-color: #333;
    position: fixed;
    bottom: 0;
    width: 100%; }
    .mobileNavbar .aside {
      display: flex;
      list-style: none;
      justify-content: center; }
    .mobileNavbar .dropdown-menu {
      display: none; }
    .mobileNavbar .nav-item .nav-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      text-align: -webkit-center;
      line-height: 15px;
      font-size: 11px;
      color: #fff;
      min-width: 60px;
      padding: .5rem 0 .2rem; }
      .mobileNavbar .nav-item .nav-link svg {
        font-size: 2em; }
      .mobileNavbar .nav-item .nav-link span {
        line-height: 2.2em; }
      .mobileNavbar .nav-item .nav-link:hover, .mobileNavbar .nav-item .nav-link:focus {
        color: white; } }

.nav a {
  text-decoration: none;
  cursor: pointer; }

.nav-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.animationHeaderInline {
  display: inline-flex; }

.nav.menubarnav {
  background: #fef3f4;
  display: flex;
  list-style: none;
  font-size: 14px;
  padding: 5px 10px;
  margin: 0;
  height: 100%;
  width: 100%;
  margin-bottom: -50px;
  padding-bottom: 53px;
  transition: transform .3s ease-in-out;
  overflow: auto;
  transition-timing-function: ease;
  scroll-behavior: smooth; }
  .nav.menubarnav .tabsMoreLeft,
  .nav.menubarnav .tabsMoreRight {
    font-size: 1.5em;
    line-height: 1.5em;
    padding: 0 5px 0 0;
    top: 0;
    opacity: 0.85;
    position: absolute;
    z-index: 1;
    height: 41px;
    width: 24px;
    background: white;
    transition: all .15s ease-in-out;
    cursor: pointer; }
    @media only screen and (min-width: 590px) {
      .nav.menubarnav .tabsMoreLeft,
      .nav.menubarnav .tabsMoreRight {
        top: 3px; } }
    .nav.menubarnav .tabsMoreLeft:hover,
    .nav.menubarnav .tabsMoreRight:hover {
      opacity: 1; }
    .nav.menubarnav .tabsMoreLeft:focus,
    .nav.menubarnav .tabsMoreRight:focus {
      outline: none; }
  .nav.menubarnav .tabsMoreLeft {
    margin: 0 0 0 -10px;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.1); }
  .nav.menubarnav .tabsMoreRight {
    right: 0;
    box-shadow: -3px 0 5px rgba(0, 0, 0, 0.1); }
    @media only screen and (min-width: 590px) {
      .nav.menubarnav .tabsMoreRight {
        right: 16px; } }
    @media only screen and (min-width: 1180px) {
      .nav.menubarnav .tabsMoreRight {
        right: 32px; } }
  @media only screen and (min-width: 590px) {
    .nav.menubarnav {
      border-top: 3px solid #a11014; } }
  .nav.menubarnav a {
    display: block;
    padding: 6px 10px;
    background: #e2dfdf;
    color: black;
    border-radius: 50px;
    margin: 0 10px 2px 0;
    white-space: nowrap; }
  .nav.menubarnav li.active a {
    background: #3398db;
    color: white; }
  @media only screen and (min-width: 590px) {
    .nav.menubarnav {
      right: 16px; } }

.teambarnav,
.tabbarnav,
.timelinebarnav {
  display: flex;
  width: 100%;
  padding-left: 8px;
  font-size: 13px; }
  .teambarnav .tabsMoreLeft,
  .teambarnav .tabsMoreRight,
  .tabbarnav .tabsMoreLeft,
  .tabbarnav .tabsMoreRight,
  .timelinebarnav .tabsMoreLeft,
  .timelinebarnav .tabsMoreRight {
    display: none;
    transition: all .15s ease-in-out; }
  @media only screen and (max-width: 589px) {
    .teambarnav,
    .tabbarnav,
    .timelinebarnav {
      max-width: 100%;
      overflow: scroll; } }

.tabbarnav > .nav-item, .timelinebarnav > .nav-item, .teambarnav > .nav-item {
  display: flex;
  flex: 1;
  transition-duration: .25s;
  list-style: none;
  text-align: center;
  position: relative;
  cursor: pointer;
  color: white;
  margin-right: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  margin-bottom: unset; }

.tabbarnav .nav-link, .timelinebarnav .nav-link, .teambarnav .nav-link {
  flex: 1;
  background: #f3d9db;
  color: black;
  border: 0;
  padding: 8px 6px;
  border-radius: 6px 6px 0px 0px; }
  .tabbarnav .nav-link.active, .timelinebarnav .nav-link.active, .teambarnav .nav-link.active {
    background: white; }

@media only screen and (max-width: 320px) {
  .tabbarnav, .timelinebarnav, .teambarnav {
    padding: 0 0 0 4px;
    letter-spacing: -0.1px; }
    .tabbarnav .nav-link, .timelinebarnav .nav-link, .teambarnav .nav-link {
      padding: 7px 4px; } }

.teambarnav,
.timelinebarnav {
  padding-right: 3px; }
  .teambarnav .nav-item,
  .timelinebarnav .nav-item {
    box-shadow: unset; }
  .teambarnav .nav-link,
  .timelinebarnav .nav-link {
    border-radius: unset;
    background: unset; }
  .teambarnav .nav-link.active,
  .timelinebarnav .nav-link.active {
    border-bottom: 3px solid #a11014; }

.icon {
  position: relative;
  padding-left: 15px;
  /* #todo 
Here's a placeholder heart! 
Should be replaced with a proper icon.
*/ }
  .icon-rhombus:before {
    content: "";
    height: 11px;
    width: 11px;
    display: block;
    left: 0;
    position: absolute;
    top: 50%;
    transform: skew(-8deg) translateY(-55%); }
  .icon-rhombus__blue:before {
    background: #174886; }
  .icon-dot:before {
    border-radius: 50%;
    content: "";
    display: block;
    height: 13px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    width: 13px;
    border: 1px solid white; }
  .icon-dot__blue:before {
    background: #174886; }
  .icon-heart:before {
    content: "\2764"; }
  .icon-white:before {
    color: white; }
  .icon-chevron::before {
    border-style: solid;
    border-width: 0.1em 0.1em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    width: 0.45em;
    top: 0.5em;
    left: 0.15em;
    position: relative;
    transform: rotate(-45deg);
    vertical-align: top; }
  .icon-chevron.right:before {
    left: 0;
    transform: rotate(45deg); }
  .icon-chevron.bottom:before {
    top: 0;
    transform: rotate(135deg); }
  .icon-chevron.left:before {
    left: 0.25em;
    transform: rotate(-135deg); }

.report-items-list {
  padding: 10px 0;
  list-style: none;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  background: white; }
  .report-items-list .list-item-pause {
    margin-bottom: 8px;
    border-bottom: 8px solid #FDD5DB; }
  .report-items-list .report-item {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    line-height: 20px;
    padding: 0 16px; }
    .report-items-list .report-item.report-item-scoreChange {
      background-color: #e8eae4; }
      .report-items-list .report-item.report-item-scoreChange.report-item-ice-hockey {
        background-color: #fdf3f5; }
    .report-items-list .report-item .goal-section-small {
      width: 40px;
      margin: 4px 0 0 8px;
      height: 25px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 1px dashed #222;
      border-bottom: 3px solid #222;
      position: relative;
      -ms-grid-row-align: center; }
      .report-items-list .report-item .goal-section-small .goal-section-marker {
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: #dd2a30; }
        .report-items-list .report-item .goal-section-small .goal-section-marker.goal-section-marker-1 {
          left: 5px;
          bottom: 5px; }
        .report-items-list .report-item .goal-section-small .goal-section-marker.goal-section-marker-2 {
          left: 18px;
          bottom: 5px; }
        .report-items-list .report-item .goal-section-small .goal-section-marker.goal-section-marker-3 {
          right: 5px;
          bottom: 5px; }
        .report-items-list .report-item .goal-section-small .goal-section-marker.goal-section-marker-4 {
          left: 5px;
          bottom: 11px; }
        .report-items-list .report-item .goal-section-small .goal-section-marker.goal-section-marker-5 {
          left: 18px;
          bottom: 11px; }
        .report-items-list .report-item .goal-section-small .goal-section-marker.goal-section-marker-51 {
          left: 13px;
          bottom: 11px; }
        .report-items-list .report-item .goal-section-small .goal-section-marker.goal-section-marker-52 {
          left: 23px;
          bottom: 11px; }
        .report-items-list .report-item .goal-section-small .goal-section-marker.goal-section-marker-6 {
          right: 5px;
          bottom: 11px; }
        .report-items-list .report-item .goal-section-small .goal-section-marker.goal-section-marker-7 {
          left: 10px;
          top: 5px; }
        .report-items-list .report-item .goal-section-small .goal-section-marker.goal-section-marker-9 {
          right: 10px;
          top: 5px; }
    .report-items-list .report-item .goal-section-large {
      width: 60px;
      margin-left: 8px;
      height: 35px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border: 1px dashed #222;
      border-bottom: 4px solid #222;
      position: relative; }
      .report-items-list .report-item .goal-section-large .goal-section-marker {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #dd2a30; }
        .report-items-list .report-item .goal-section-large .goal-section-marker.goal-section-marker-1 {
          left: 5px;
          bottom: 5px; }
        .report-items-list .report-item .goal-section-large .goal-section-marker.goal-section-marker-2 {
          left: 25px;
          bottom: 5px; }
        .report-items-list .report-item .goal-section-large .goal-section-marker.goal-section-marker-3 {
          right: 5px;
          bottom: 5px; }
        .report-items-list .report-item .goal-section-large .goal-section-marker.goal-section-marker-4 {
          left: 5px;
          bottom: 13px; }
        .report-items-list .report-item .goal-section-large .goal-section-marker.goal-section-marker-5 {
          left: 25px;
          bottom: 13px; }
        .report-items-list .report-item .goal-section-large .goal-section-marker.goal-section-marker-51 {
          left: 20px;
          bottom: 13px; }
        .report-items-list .report-item .goal-section-large .goal-section-marker.goal-section-marker-52 {
          left: 30px;
          bottom: 13px; }
        .report-items-list .report-item .goal-section-large .goal-section-marker.goal-section-marker-6 {
          right: 5px;
          bottom: 13px; }
        .report-items-list .report-item .goal-section-large .goal-section-marker.goal-section-marker-7 {
          left: 15px;
          top: 5px; }
        .report-items-list .report-item .goal-section-large .goal-section-marker.goal-section-marker-9 {
          right: 15px;
          top: 5px; }
    .report-items-list .report-item .report-item-body {
      flex-grow: 1;
      margin: 10px 10px 10px 0; }
      .report-items-list .report-item .report-item-body .report-item-shot-content {
        display: flex;
        align-items: flex-start; }
        .report-items-list .report-item .report-item-body .report-item-shot-content .report-item-shot-info {
          flex-grow: 1; }
      .report-items-list .report-item .report-item-body .report-item-scorechange-content {
        display: flex; }
        .report-items-list .report-item .report-item-body .report-item-scorechange-content .report-item-scorechange-info {
          flex-grow: 1; }
      .report-items-list .report-item .report-item-body .report-item-header {
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 4px; }
      .report-items-list .report-item .report-item-body .report-item-comment {
        opacity: .75; }
      .report-items-list .report-item .report-item-body .report-item-time {
        padding: 16px 16px 0;
        font-size: 12px;
        text-align: right;
        white-space: nowrap;
        flex-shrink: 0; }
  .report-items-list .report-item-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    margin-top: 10px; }
    .report-items-list .report-item-type .report-item-type-dot {
      width: 100%;
      position: relative;
      line-height: 1rem;
      text-align: center; }
      .report-items-list .report-item-type .report-item-type-dot:after {
        width: 5px;
        height: 5px;
        display: inline-block;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.16);
        content: ""; }
    .report-items-list .report-item-type:before {
      top: 1rem;
      height: 100%;
      content: "";
      display: block;
      position: absolute;
      border-left: 1px solid #e2e4e6;
      left: 15px; }
    .report-items-list .report-item-type .report-item-type-icon {
      position: relative; }
      .report-items-list .report-item-type .report-item-type-icon .report-icon {
        width: 30px;
        height: auto;
        display: block; }

.report-items-list-item .report-item-type {
  width: 30px;
  margin-right: 10px; }

.report-items-list-item .report-item-time {
  padding: 10px 0;
  flex-shrink: 0;
  text-align: right; }

.teaser-match {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  background-color: #feebee;
  padding: 7px 16px;
  box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.07);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 1px;
  transition: all 0.15s ease;
  text-decoration: none;
  color: #222222;
  /* #todo 
   .heading should be a separate component with a different name. 
   But what? 
  */ }
  .teaser-match .game-spel-tips {
    margin-top: 4px;
    text-align: center;
    font-size: 13px; }
    .teaser-match .game-spel-tips:first-child {
      margin-top: 4px; }
  .teaser-match a:hover {
    text-decoration: none; }
  .teaser-match-number {
    position: absolute;
    top: 0;
    left: 0;
    font-family: verdana, helvetica, sans-serif;
    font-size: 21px;
    font-weight: 700;
    color: #766f6f; }
  .teaser-match .heading {
    font-family: Tahoma, Arial, sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 1rem;
    position: absolute;
    top: -16px;
    left: 16px;
    background: black;
    color: white;
    transform: skew(-10deg);
    border-radius: 8px 0 8px 0px;
    padding: 3px 12px;
    letter-spacing: 0.4px; }
  .teaser-match-odds, .teaser-match-bet {
    display: flex;
    flex: 0 0 5%; }
  .teaser-match .abIconArrowConvexDown.right:before {
    display: inline-block;
    transform: rotate(0deg); }
  .teaser-match:hover, .teaser-match:active {
    box-shadow: 0 0px 3px 1px rgba(0, 0, 0, 0.15);
    opacity: 0.9;
    text-decoration: none; }

.teaser-match-odds {
  margin: 0 -16px 1px;
  padding: 6px 18%;
  background: #e4cfd2;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1em;
  display: flex; }
  .teaser-match-odds .column {
    display: flex; }
    .teaser-match-odds .column:nth-child(1), .teaser-match-odds .column:nth-child(3) {
      flex: 0 0 20%; }
    .teaser-match-odds .column:nth-child(2) {
      flex: 0 0 60%; }
    .teaser-match-odds .column div {
      flex: 1 0;
      text-align: center; }
  .teaser-match-odds:last-child {
    margin-bottom: 0; }
  .teaser-match-odds-number {
    position: absolute;
    left: 16px;
    font-family: verdana, helvetica, sans-serif;
    font-size: 21px;
    font-weight: bold;
    color: #766f6f; }

.teaser-match-heading {
  position: relative;
  justify-content: center;
  display: flex;
  padding: 0 10px 7px;
  align-items: center;
  color: #222222; }
  .teaser-match-heading:before {
    content: " ";
    display: block;
    position: absolute;
    bottom: 0;
    left: -8px;
    right: -8px;
    height: 1px;
    background: rgba(34, 34, 34, 0.1); }
    @media only screen and (min-width: 768px) {
      .teaser-match-heading:before {
        left: -16px;
        right: -16px; } }
  .teaser-match-heading .time,
  .teaser-match-heading .score {
    padding: 8px 12px;
    background: #766f6f;
    color: white;
    font-size: 10px;
    margin: 0 20px; }
  .teaser-match-heading .team {
    display: flex;
    align-items: center;
    flex: 2 0;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden; }
    .teaser-match-heading .team-left {
      justify-content: flex-end; }
      .teaser-match-heading .team-left .team-name {
        padding-right: 0;
        text-align: right; }
  .teaser-match-heading .team-logo {
    max-height: 32px;
    width: auto; }
    .teaser-match-heading .team-logo__left {
      margin-right: 6px; }
    .teaser-match-heading .team-logo__right {
      margin-left: 6px; }
  .teaser-match-heading .teaser-match-link {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.1em; }
  .teaser-match-heading .team-name {
    position: relative;
    padding-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em; }

.teaser-match-content {
  margin-top: 14px;
  padding: 8px 0;
  display: flex; }

.event-grades + .teaser-match-content {
  margin-top: 0; }

.teaser-match-content-editor {
  margin-top: -15px; }
  .teaser-match-content-editor img {
    width: 50px;
    height: auto;
    clip-path: circle(49.4% at center); }
  .teaser-match-content-editor-name {
    margin-top: 4px;
    font-size: 9px;
    text-transform: uppercase; }

.teaser-match-content-text {
  padding: 10px 13px;
  margin: 0 0 0 12px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0 9px 9px 9px;
  width: 100%;
  font-size: 16px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.02); }
  .teaser-match-content-text p:not(:last-child) {
    margin-bottom: 1rem; }

aside .abTheme-sportbladet.abThemeBgTeaser.teaser-match {
  background-color: inherit; }

aside .teaser-match {
  background-color: inherit;
  padding: 0; }
  aside .teaser-match-number {
    letter-spacing: -2px;
    top: 0.45em;
    left: 4px;
    width: 1.2em;
    text-align: center;
    font-size: 18px;
    line-height: 23px; }
  aside .teaser-match.reduce-margin {
    margin-top: 5px; }
  aside .teaser-match .team {
    font-size: 12px;
    line-height: 1em; }
  aside .teaser-match .teaser-match-heading {
    background: #feebee;
    padding: 8px 20px;
    margin: 0; }
    aside .teaser-match .teaser-match-heading:before {
      left: 0;
      right: 0; }
    aside .teaser-match .teaser-match-heading .time,
    aside .teaser-match .teaser-match-heading .score {
      padding: 6px;
      margin: 0 7px; }
  aside .teaser-match .team-logo {
    max-height: 20px;
    width: auto; }
    aside .teaser-match .team-logo__left {
      margin-right: 4px; }
    aside .teaser-match .team-logo__right {
      margin-left: 4px; }
  aside .teaser-match .teaser-match-link {
    right: 9px;
    top: 0.52rem;
    font-size: 0.9em; }
  aside .teaser-match .heading {
    z-index: 2;
    top: -14px;
    left: 15px;
    font-size: 11px;
    line-height: 14px; }
  aside .teaser-match .teaser-match-odds {
    padding: 5px 8%;
    margin: 0 0 1px; }
    aside .teaser-match .teaser-match-odds .column:nth-child(3) {
      display: none; }
    aside .teaser-match .teaser-match-odds:last-child {
      margin-bottom: 0; }
  aside .teaser-match .teaser-match-content {
    padding: 4px 0;
    margin: 8px; }
  aside .teaser-match .teaser-match-content-editor {
    margin-top: -8px; }
    aside .teaser-match .teaser-match-content-editor img {
      width: 28px;
      height: auto; }
  aside .teaser-match .teaser-match-content-text {
    font-size: 12px;
    margin-left: 2px;
    padding: 5px 8px; }
  aside .teaser-match .teaser-match-recommendation {
    font-size: 12px;
    text-align: center;
    padding: 3px 3px 0; }
  aside .teaser-match a {
    text-decoration: none; }

@media only screen and (max-width: 589px) {
  .tab-content .teaser-match-heading {
    padding: 0 20px 9px; }
  .tab-content .teaser-match-odds {
    margin: 0 -8px 1px; } }

@media only screen and (max-width: 767px) {
  .tab-content .teaser-match-number {
    letter-spacing: -1px; }
  .teaser-match {
    padding: 8px; }
    .teaser-match-number {
      font-size: 17px;
      top: 0;
      left: -3px; }
    .teaser-match .team-name {
      font-size: 15px; }
    .teaser-match .team-logo {
      max-height: 24px; }
    .teaser-match-heading {
      padding-bottom: 9px; }
      .teaser-match-heading .time,
      .teaser-match-heading .score {
        padding: 5px 8px;
        margin: 0 7px; }
      .teaser-match-heading .teaser-match-link {
        top: -4px;
        right: 2px; }
    .teaser-match .event-grades .game-suggestions {
      margin: 0 10px; }
      .teaser-match .event-grades .game-suggestions .game-suggestion {
        padding: 5px 0; }
    .teaser-match .event-grades .grade-img {
      height: 15px; }
    .teaser-match-content-text {
      margin-top: 12px;
      margin-left: 4px;
      font-size: 15px;
      padding: 6px 9px; }
    .teaser-match-odds {
      padding: 6px 10%;
      margin: 0 -8px 1px; } }

.events-sidebar-component .today-date,
.events-sidebar-component .matchSideHeader,
.sidebar-component .today-date,
.sidebar-component .matchSideHeader {
  background-color: #a11014;
  margin-bottom: 9px;
  color: #fefefe;
  font-weight: 700;
  font-size: 11px;
  line-height: 1;
  overflow: hidden;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: bottom;
  border-radius: 2px;
  display: inline-block;
  padding: 4px 8px 5px; }

.events-sidebar-component .teaser-match .teaser-match-heading .time,
.events-sidebar-component .teaser-match .teaser-match-heading .score,
.sidebar-component .teaser-match .teaser-match-heading .time,
.sidebar-component .teaser-match .teaser-match-heading .score {
  padding: 6px;
  margin: 0 10px; }

.events-sidebar-component .teaser-match .teaser-match-odds .column:nth-child(3),
.sidebar-component .teaser-match .teaser-match-odds .column:nth-child(3) {
  display: none; }

.events-sidebar-component .teaser-match .teaser-match-content,
.sidebar-component .teaser-match .teaser-match-content {
  padding: 4px 0;
  margin: 8px; }

.events-sidebar-component .teaser-match .teaser-match-content-editor img,
.sidebar-component .teaser-match .teaser-match-content-editor img {
  width: 28px;
  height: auto; }

.events-sidebar-component .teaser-match .teaser-match-content-text,
.sidebar-component .teaser-match .teaser-match-content-text {
  background: #fde9ec; }

.events-sidebar-component .teaser-match .teaser-match-recommendation,
.sidebar-component .teaser-match .teaser-match-recommendation {
  font-size: 12px;
  text-align: center;
  padding: 3px 3px 0; }

.widget__in-iframe {
  width: 100% !important;
  min-height: 100vh;
  display: inline-block !important;
  margin-top: -45px;
  padding-bottom: 40px;
  background-color: #FDD5DC; }
  .widget__in-iframe .teaser-match {
    box-shadow: unset;
    border: 1px solid rgba(0, 0, 0, 0.07); }
    .widget__in-iframe .teaser-match:hover {
      border-color: rgba(0, 0, 0, 0.05); }
  .widget__in-iframe .events-section {
    padding: 16px; }
    .widget__in-iframe .events-section h2 {
      font-family: arial, sans-serif;
      font-size: 11px;
      font-weight: 700;
      line-height: 16.8px;
      -webkit-font-smoothing: antialiased;
      border-radius: unset;
      display: inline-block;
      margin-bottom: unset;
      vertical-align: unset;
      padding: 5px 5px 4px;
      /*
      h2 when iframe is in aside column
      background: #dd2a30;
      font-size: 12px;
      line-height: 12px;
      height: unset;
      padding: 4px 6px;
      */ }
    .widget__in-iframe .events-section .teaser-match:not(.reduce-margin), .widget__in-iframe .events-section:not(:first-of-type) {
      margin-top: 1.2rem; }
  .widget__in-iframe .btn-show-more {
    transition: all 0.15s ease;
    cursor: pointer;
    margin-top: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    color: #222222;
    font-weight: bold;
    font-size: 12px;
    padding: 12px;
    display: block;
    text-align: center;
    text-decoration: none;
    position: fixed;
    bottom: 0;
    width: 100%; }
    .widget__in-iframe .btn-show-more:hover {
      color: #444; }

button,
.btn,
.button {
  cursor: pointer; }
  button.disabled, button:disabled,
  .btn.disabled,
  .btn:disabled,
  .button.disabled,
  .button:disabled {
    opacity: 0.65;
    cursor: not-allowed; }
  button:hover,
  .btn:hover,
  .button:hover {
    opacity: 0.95; }
  button.link,
  .btn.link,
  .button.link {
    background: transparent;
    text-decoration: underline;
    color: inherit; }

.switch {
  display: inline-block;
  position: relative;
  margin-top: 1px;
  font-size: 16px;
  line-height: 24px; }
  .switch__input {
    position: absolute;
    top: -3px;
    left: 0;
    width: 40px;
    margin: 0;
    height: 21px;
    opacity: 0;
    z-index: 0; }
  .switch__label {
    display: flex;
    align-items: center;
    padding: 0 0 0 44px;
    cursor: pointer;
    color: white;
    font-size: 12px;
    line-height: 1em;
    height: 21px; }
    .switch__label::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      width: 36px;
      height: 14px;
      background-color: rgba(0, 0, 0, 0.26);
      border-radius: 14px;
      z-index: 1;
      transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1); }
    .switch__label::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 14px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      z-index: 2;
      transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      transition-property: left, background-color; }
  .switch__input:checked + .switch__label:before {
    background-color: rgba(51, 152, 219, 0.5); }
  .switch__input:checked + .switch__label:after {
    left: 16px;
    background-color: #3398db; }
  .switch.right .switch__input {
    left: unset;
    right: 0; }
  .switch.right .switch__label {
    padding: 0 44px 0 0; }
    .switch.right .switch__label:before {
      left: auto;
      right: 0; }
    .switch.right .switch__label:after {
      left: auto;
      right: 0;
      transition-property: right, background-color; }
  .switch.right .switch__input:checked + .switch__label:after {
    right: 16px; }

article.odds {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem; }
  article.odds .teaser-match-heading {
    padding-top: 7px;
    background: #feebee; }
    article.odds .teaser-match-heading:before {
      left: 0;
      right: 0; }
      @media only screen and (min-width: 768px) {
        article.odds .teaser-match-heading:before {
          left: 0;
          right: 0; } }
    article.odds .teaser-match-heading .team {
      font-size: 14px; }
    article.odds .teaser-match-heading .team-name {
      margin-top: 2px; }
  article.odds .teaser-match-content {
    flex-wrap: wrap;
    padding: 0; }
    article.odds .teaser-match-content .teaser-match-content-text {
      background: unset;
      box-shadow: unset;
      flex: 1 0 100%;
      font-family: helvetica, arial, sans-serif;
      font-style: italic;
      margin: unset;
      padding: 20px 20px 0;
      font-size: 0.8rem;
      line-height: 1.3rem; }
      article.odds .teaser-match-content .teaser-match-content-text p:not(:last-child) {
        margin-bottom: 1.25rem; }
  article.odds .teaser-match-content-editor {
    position: relative;
    margin-top: unset;
    width: 100px;
    min-height: 80px;
    text-align: center; }
    article.odds .teaser-match-content-editor .icon {
      position: absolute;
      top: 40px;
      left: 25px; }
  article.odds .article-img {
    position: relative; }
    article.odds .article-img img {
      width: 100%;
      height: 320px;
      display: block; }
    article.odds .article-img .like,
    article.odds .article-img .venue,
    article.odds .article-img .time {
      position: absolute;
      color: white;
      font-size: 0.8rem; }
    article.odds .article-img .like {
      top: 10px;
      left: 15px; }
    article.odds .article-img .venue {
      bottom: 10px;
      left: 15px; }
    article.odds .article-img .time {
      bottom: 10px;
      right: 15px; }
  article.odds .teaser-match-odds {
    margin: 0; }
  article.odds .teaser-match-content-editor-tips {
    margin-top: 8px;
    width: calc(100% - 116px);
    font-size: 0.8rem;
    line-height: 1.5rem; }
    article.odds .teaser-match-content-editor-tips li {
      line-height: 1.2em;
      font-style: italic;
      margin-bottom: 6px; }
      article.odds .teaser-match-content-editor-tips li.icon-rhombus:before {
        top: 0.55em; }
      article.odds .teaser-match-content-editor-tips li span {
        font-family: "arial narrow", Arial, Helvetica, sans-serif; }
      article.odds .teaser-match-content-editor-tips li span:first-of-type {
        text-transform: uppercase;
        font-weight: bold; }
  article.odds .abBtn.abBtnShare {
    padding: 8px 7px 5px; }
    article.odds .abBtn.abBtnShare .abBtnIcon {
      font-size: 0.8rem;
      line-height: 0.8rem; }
  article.odds .share-panel {
    margin: 15px 0 0 15px; }

.article-affiliate-ad-container {
  padding: 10px;
  margin-right: -10px;
  margin-left: -10px; }
  .article-affiliate-ad-container::before {
    top: -1px;
    margin-left: 10px; }
  .article-affiliate-ad-container .gdprAdTransparancyCogWheelButton {
    position: absolute;
    height: 15px;
    width: 15px;
    top: -3px;
    right: 5px;
    background-repeat: no-repeat; }

.article-affiliate-ad {
  position: relative;
  border-top: 2px solid #a11014;
  border-bottom: 2px solid #a11014;
  margin-bottom: 2rem;
  font-size: 12px;
  color: #00264f; }
  .article-affiliate-ad a,
  .article-affiliate-ad a:hover,
  .article-affiliate-ad a:active {
    color: #00264f;
    text-decoration: underline; }
  .article-affiliate-ad .teaser-match-odds-heading {
    font-weight: bold; }
  .article-affiliate-ad-heading {
    background: #f5f1f1; }
  .article-affiliate-ad .teaser-match-odds {
    text-transform: unset;
    margin: unset;
    font-size: inherit;
    padding: 8px 5%;
    background: #fcecef;
    border-top: 1px solid #fef3f4; }
    .article-affiliate-ad .teaser-match-odds .show-more {
      color: #4a4a4a;
      margin-top: 1rem;
      text-decoration: none; }

.match-stats .nav-tabs {
  background-color: unset;
  border-bottom: unset; }

.match-stats .tab-content {
  background: white; }
  .match-stats .tab-content .teaser-match-heading {
    margin: 0 4px; }

.match-stats-content {
  margin: 0 4px;
  padding-top: 3px;
  background: white; }

.match-stats-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-size: 14px;
  min-height: 70px; }

.match-stats-info {
  font-family: "helvetica neue", helvetica;
  padding: 1px 8px;
  color: #00264f;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-style: italic;
  background: #FDD5DB; }
  .match-stats-info .league {
    float: left; }
  .match-stats-info .time {
    float: right; }

.tab-content .teaser-match-heading:after {
  display: none; }

.tab-pane.active {
  padding-bottom: 1.5em; }

@media only screen and (max-width: 589px) {
  article.odds {
    margin: 0 -16px; } }

@media only screen and (max-width: 767px) {
  .team-name {
    font-size: 15px; } }

.articles-list-main-page,
.article-main-page {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%; }
  .articles-list-main-page .tab-content .empty-message,
  .article-main-page .tab-content .empty-message {
    min-height: 300px;
    padding: 1rem;
    font-size: 14px; }
  .articles-list-main-page .tab-content.menubartabContent,
  .article-main-page .tab-content.menubartabContent {
    margin-top: 5px; }
    @media only screen and (max-width: 589px) {
      .articles-list-main-page .tab-content.menubartabContent,
      .article-main-page .tab-content.menubartabContent {
        padding: 0 16px; } }
  .articles-list-main-page .speltipdesc,
  .article-main-page .speltipdesc {
    font: 18px Arial, Helvetica, Verdana, Geneva, sans-serif;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 2px;
    margin-top: 10px; }
  .articles-list-main-page .speltipdesc + .speltipdesc,
  .article-main-page .speltipdesc + .speltipdesc {
    text-indent: 1em; }
  .articles-list-main-page .speltipsPara,
  .article-main-page .speltipsPara {
    font: 16px Arial, Helvetica, Verdana, Geneva, sans-serif;
    line-height: 1.3;
    margin-top: 0; }
  .articles-list-main-page .speltipsPara + .speltipsPara,
  .article-main-page .speltipsPara + .speltipsPara {
    text-indent: 1em; }
  .articles-list-main-page .point-texts,
  .article-main-page .point-texts {
    margin: 15px; }
    .articles-list-main-page .point-texts .speltipsPara,
    .article-main-page .point-texts .speltipsPara {
      margin-bottom: 5px;
      text-indent: 0em; }
      .articles-list-main-page .point-texts .speltipsPara .point-num,
      .article-main-page .point-texts .speltipsPara .point-num {
        background-color: red;
        padding: 2px 5px;
        border-radius: 4px;
        color: white; }
      .articles-list-main-page .point-texts .speltipsPara .point-desc,
      .article-main-page .point-texts .speltipsPara .point-desc {
        margin-left: 5px; }
  .articles-list-main-page .article-image-container,
  .article-main-page .article-image-container {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 20px;
    margin-bottom: 20px; }
    @media only screen and (min-width: 590px) {
      .articles-list-main-page .article-image-container,
      .article-main-page .article-image-container {
        margin-left: unset;
        margin-right: unset; } }
  .articles-list-main-page .article-image,
  .article-main-page .article-image {
    display: block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    @media only screen and (min-width: 1180px) {
      .articles-list-main-page .article-image,
      .article-main-page .article-image {
        width: 100%; } }
  .articles-list-main-page .article-image-credits,
  .article-main-page .article-image-credits {
    text-transform: uppercase;
    font-family: Arial;
    font-size: 12px;
    line-height: 1.42;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 4px; }
    @media only screen and (min-width: 590px) {
      .articles-list-main-page .article-image-credits,
      .article-main-page .article-image-credits {
        margin-left: unset; } }
  .articles-list-main-page .articles-list,
  .articles-list-main-page .article,
  .article-main-page .articles-list,
  .article-main-page .article {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    width: 100%; }
    @media only screen and (min-width: 590px) {
      .articles-list-main-page .articles-list,
      .articles-list-main-page .article,
      .article-main-page .articles-list,
      .article-main-page .article {
        padding-top: 16px; } }
    .articles-list-main-page .articles-list .article-list-main-box .speltips-header,
    .articles-list-main-page .articles-list .article-main-box .speltips-header,
    .articles-list-main-page .article .article-list-main-box .speltips-header,
    .articles-list-main-page .article .article-main-box .speltips-header,
    .article-main-page .articles-list .article-list-main-box .speltips-header,
    .article-main-page .articles-list .article-main-box .speltips-header,
    .article-main-page .article .article-list-main-box .speltips-header,
    .article-main-page .article .article-main-box .speltips-header {
      padding: 6px 16px;
      border-top-width: 3px !important;
      border-top-style: solid;
      margin-bottom: 16px;
      border-top-color: #dd2a30 !important;
      background-color: #fff; }
      .articles-list-main-page .articles-list .article-list-main-box .speltips-header .spel-para,
      .articles-list-main-page .articles-list .article-main-box .speltips-header .spel-para,
      .articles-list-main-page .article .article-list-main-box .speltips-header .spel-para,
      .articles-list-main-page .article .article-main-box .speltips-header .spel-para,
      .article-main-page .articles-list .article-list-main-box .speltips-header .spel-para,
      .article-main-page .articles-list .article-main-box .speltips-header .spel-para,
      .article-main-page .article .article-list-main-box .speltips-header .spel-para,
      .article-main-page .article .article-main-box .speltips-header .spel-para {
        line-height: 1.3;
        margin-top: 7px;
        font-size: 14px;
        padding-top: 8px; }
    .articles-list-main-page .articles-list .article-list-main-box .article-list,
    .articles-list-main-page .articles-list .article-main-box .article-list,
    .articles-list-main-page .article .article-list-main-box .article-list,
    .articles-list-main-page .article .article-main-box .article-list,
    .article-main-page .articles-list .article-list-main-box .article-list,
    .article-main-page .articles-list .article-main-box .article-list,
    .article-main-page .article .article-list-main-box .article-list,
    .article-main-page .article .article-main-box .article-list {
      border-bottom: 1px solid rgba(34, 34, 34, 0.1);
      margin-bottom: 5px;
      margin-top: 35px;
      transition: all 0.2s ease; }
      .articles-list-main-page .articles-list .article-list-main-box .article-list .teaser-match .teaser-match-heading .team .team-logo-right,
      .articles-list-main-page .articles-list .article-main-box .article-list .teaser-match .teaser-match-heading .team .team-logo-right,
      .articles-list-main-page .article .article-list-main-box .article-list .teaser-match .teaser-match-heading .team .team-logo-right,
      .articles-list-main-page .article .article-main-box .article-list .teaser-match .teaser-match-heading .team .team-logo-right,
      .article-main-page .articles-list .article-list-main-box .article-list .teaser-match .teaser-match-heading .team .team-logo-right,
      .article-main-page .articles-list .article-main-box .article-list .teaser-match .teaser-match-heading .team .team-logo-right,
      .article-main-page .article .article-list-main-box .article-list .teaser-match .teaser-match-heading .team .team-logo-right,
      .article-main-page .article .article-main-box .article-list .teaser-match .teaser-match-heading .team .team-logo-right {
        margin-left: 6px;
        float: right; }
      .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link,
      .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link,
      .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link,
      .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link,
      .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link,
      .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link,
      .article-main-page .article .article-list-main-box .article-list .article-list-desc-link,
      .article-main-page .article .article-main-box .article-list .article-list-desc-link {
        width: 100%;
        position: relative;
        padding-bottom: 4px;
        text-decoration: none;
        color: #000;
        box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.07);
        transition: all 0.15s ease; }
        .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link:hover,
        .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link:hover,
        .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link:hover,
        .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link:hover,
        .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link:hover,
        .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link:hover,
        .article-main-page .article .article-list-main-box .article-list .article-list-desc-link:hover,
        .article-main-page .article .article-main-box .article-list .article-list-desc-link:hover {
          box-shadow: 0 0px 3px 1px rgba(0, 0, 0, 0.15); }
        .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link h3,
        .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link h3,
        .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link h3,
        .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link h3,
        .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link h3,
        .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link h3,
        .article-main-page .article .article-list-main-box .article-list .article-list-desc-link h3,
        .article-main-page .article .article-main-box .article-list .article-list-desc-link h3 {
          font-size: 34px;
          letter-spacing: -1.4px; }
          @media only screen and (max-width: 767px) {
            .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link h3,
            .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link h3,
            .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link h3,
            .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link h3,
            .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link h3,
            .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link h3,
            .article-main-page .article .article-list-main-box .article-list .article-list-desc-link h3,
            .article-main-page .article .article-main-box .article-list .article-list-desc-link h3 {
              font-size: 22px; } }
        .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link:hover h3,
        .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link:hover h3,
        .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link:hover h3,
        .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link:hover h3,
        .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link:hover h3,
        .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link:hover h3,
        .article-main-page .article .article-list-main-box .article-list .article-list-desc-link:hover h3,
        .article-main-page .article .article-main-box .article-list .article-list-desc-link:hover h3 {
          text-decoration: underline; }
      .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data,
      .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data,
      .articles-list-main-page .article .article-list-main-box .article-list .article-list-data,
      .articles-list-main-page .article .article-main-box .article-list .article-list-data,
      .article-main-page .articles-list .article-list-main-box .article-list .article-list-data,
      .article-main-page .articles-list .article-main-box .article-list .article-list-data,
      .article-main-page .article .article-list-main-box .article-list .article-list-data,
      .article-main-page .article .article-main-box .article-list .article-list-data {
        padding: 10px 16px; }
      .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link,
      .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data,
      .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link,
      .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data,
      .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link,
      .articles-list-main-page .article .article-list-main-box .article-list .article-list-data,
      .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link,
      .articles-list-main-page .article .article-main-box .article-list .article-list-data,
      .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link,
      .article-main-page .articles-list .article-list-main-box .article-list .article-list-data,
      .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link,
      .article-main-page .articles-list .article-main-box .article-list .article-list-data,
      .article-main-page .article .article-list-main-box .article-list .article-list-desc-link,
      .article-main-page .article .article-list-main-box .article-list .article-list-data,
      .article-main-page .article .article-main-box .article-list .article-list-desc-link,
      .article-main-page .article .article-main-box .article-list .article-list-data {
        background-color: #feebee;
        display: block; }
        .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img,
        .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img,
        .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img,
        .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img,
        .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img,
        .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img,
        .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img,
        .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img,
        .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img,
        .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img,
        .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img,
        .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img,
        .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img,
        .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img,
        .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img,
        .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img {
          display: block; }
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image,
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img .spel-image,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image,
          .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image,
          .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img .spel-image,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image,
          .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image,
          .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img .spel-image,
          .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image,
          .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image,
          .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image,
          .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img .spel-image {
            position: relative; }
            .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .image-class,
            .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image .image-class,
            .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .image-class,
            .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img .spel-image .image-class,
            .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .image-class,
            .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image .image-class,
            .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .image-class,
            .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img .spel-image .image-class,
            .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .image-class,
            .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image .image-class,
            .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .image-class,
            .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img .spel-image .image-class,
            .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .image-class,
            .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image .image-class,
            .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .image-class,
            .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img .spel-image .image-class,
            .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img {
              width: 100%;
              position: absolute;
              left: 0; }
            .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img .spel-image .spel-image > img,
            .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img .spel-image .spel-image > img {
              max-width: 168px;
              max-height: 160px;
              top: 3%; }
        .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left,
        .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-left,
        .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-left,
        .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-left,
        .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left,
        .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-left,
        .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-left,
        .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-left,
        .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left,
        .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-left,
        .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-left,
        .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-left,
        .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left,
        .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-left,
        .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-left,
        .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-left {
          display: block;
          width: 30%;
          float: left;
          max-width: 180px;
          margin: 10px; }
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image-left,
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-left .spel-image-left,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image-left,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-left .spel-image-left,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image-left,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-left .spel-image-left,
          .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image-left,
          .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-left .spel-image-left,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image-left,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-left .spel-image-left,
          .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image-left,
          .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-left .spel-image-left,
          .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image-left,
          .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-left .spel-image-left,
          .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image-left,
          .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-left .spel-image-left {
            position: relative; }
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left .image-class,
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image > img,
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-left .image-class,
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-left .spel-image > img,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-left .image-class,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image > img,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-left .image-class,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-left .spel-image > img,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left .image-class,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image > img,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-left .image-class,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-left .spel-image > img,
          .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-left .image-class,
          .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image > img,
          .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-left .image-class,
          .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-left .spel-image > img,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left .image-class,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image > img,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-left .image-class,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-left .spel-image > img,
          .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-left .image-class,
          .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image > img,
          .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-left .image-class,
          .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-left .spel-image > img,
          .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left .image-class,
          .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image > img,
          .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-left .image-class,
          .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-left .spel-image > img,
          .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-left .image-class,
          .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-left .spel-image > img,
          .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-left .image-class,
          .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-left .spel-image > img {
            width: 100%; }
        .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right,
        .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-right,
        .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-right,
        .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-right,
        .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right,
        .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-right,
        .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-right,
        .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-right,
        .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right,
        .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-right,
        .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-right,
        .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-right,
        .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right,
        .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-right,
        .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-right,
        .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-right {
          display: block;
          width: 30%;
          float: right;
          max-width: 180px;
          margin: 10px; }
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image-right,
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-right .spel-image-right,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image-right,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-right .spel-image-right,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image-right,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-right .spel-image-right,
          .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image-right,
          .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-right .spel-image-right,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image-right,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-right .spel-image-right,
          .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image-right,
          .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-right .spel-image-right,
          .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image-right,
          .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-right .spel-image-right,
          .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image-right,
          .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-right .spel-image-right {
            position: relative; }
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right .image-class,
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image > img,
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-right .image-class,
          .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-right .spel-image > img,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-right .image-class,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image > img,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-right .image-class,
          .articles-list-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-right .spel-image > img,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right .image-class,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image > img,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-right .image-class,
          .articles-list-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-right .spel-image > img,
          .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-right .image-class,
          .articles-list-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image > img,
          .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-right .image-class,
          .articles-list-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-right .spel-image > img,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right .image-class,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image > img,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-right .image-class,
          .article-main-page .articles-list .article-list-main-box .article-list .article-list-data .article-list-des-img-right .spel-image > img,
          .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-right .image-class,
          .article-main-page .articles-list .article-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image > img,
          .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-right .image-class,
          .article-main-page .articles-list .article-main-box .article-list .article-list-data .article-list-des-img-right .spel-image > img,
          .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right .image-class,
          .article-main-page .article .article-list-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image > img,
          .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-right .image-class,
          .article-main-page .article .article-list-main-box .article-list .article-list-data .article-list-des-img-right .spel-image > img,
          .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-right .image-class,
          .article-main-page .article .article-main-box .article-list .article-list-desc-link .article-list-des-img-right .spel-image > img,
          .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-right .image-class,
          .article-main-page .article .article-main-box .article-list .article-list-data .article-list-des-img-right .spel-image > img {
            width: 100%; }
      .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-title,
      .articles-list-main-page .articles-list .article-main-box .article-list .article-list-title,
      .articles-list-main-page .article .article-list-main-box .article-list .article-list-title,
      .articles-list-main-page .article .article-main-box .article-list .article-list-title,
      .article-main-page .articles-list .article-list-main-box .article-list .article-list-title,
      .article-main-page .articles-list .article-main-box .article-list .article-list-title,
      .article-main-page .article .article-list-main-box .article-list .article-list-title,
      .article-main-page .article .article-main-box .article-list .article-list-title {
        font-size: 34px;
        letter-spacing: -1.4px;
        margin: 0 0 8px;
        color: #000;
        line-height: 1.08em;
        max-width: 100%; }
      .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-title:hover,
      .articles-list-main-page .articles-list .article-main-box .article-list .article-list-title:hover,
      .articles-list-main-page .article .article-list-main-box .article-list .article-list-title:hover,
      .articles-list-main-page .article .article-main-box .article-list .article-list-title:hover,
      .article-main-page .articles-list .article-list-main-box .article-list .article-list-title:hover,
      .article-main-page .articles-list .article-main-box .article-list .article-list-title:hover,
      .article-main-page .article .article-list-main-box .article-list .article-list-title:hover,
      .article-main-page .article .article-main-box .article-list .article-list-title:hover {
        text-decoration: underline; }
      .articles-list-main-page .articles-list .article-list-main-box .article-list .article-list-desc-sub-title,
      .articles-list-main-page .articles-list .article-main-box .article-list .article-list-desc-sub-title,
      .articles-list-main-page .article .article-list-main-box .article-list .article-list-desc-sub-title,
      .articles-list-main-page .article .article-main-box .article-list .article-list-desc-sub-title,
      .article-main-page .articles-list .article-list-main-box .article-list .article-list-desc-sub-title,
      .article-main-page .articles-list .article-main-box .article-list .article-list-desc-sub-title,
      .article-main-page .article .article-list-main-box .article-list .article-list-desc-sub-title,
      .article-main-page .article .article-main-box .article-list .article-list-desc-sub-title {
        font-size: 16px;
        margin-top: 4px; }
  .articles-list-main-page .default-suggestion-link,
  .article-main-page .default-suggestion-link {
    font-size: 16px;
    font-weight: bold;
    color: #a22b15;
    cursor: pointer; }
  .articles-list-main-page .date-and-tags,
  .article-main-page .date-and-tags {
    line-height: 1.2;
    color: rgba(34, 34, 34, 0.5);
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 4px;
    display: flex; }
    .articles-list-main-page .date-and-tags .datetime,
    .article-main-page .date-and-tags .datetime {
      margin-right: 15px; }
    .articles-list-main-page .date-and-tags .article-tags,
    .article-main-page .date-and-tags .article-tags {
      font-weight: 700;
      color: #a11014;
      margin-right: 15px;
      text-transform: uppercase; }

.articles-list-main-page .article-image,
.article-main-page .article-image {
  width: 100%; }

@media screen and (min-width: 768px) {
  .articles-list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .articles-list .articles-list-main .sportsbladet_layout {
      flex-direction: row; } }

h3 {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: 700; }

.event-grades {
  justify-content: center;
  display: flex;
  flex: 0 0 12%;
  padding: 5px 10px;
  align-items: center;
  color: #222222; }
  .event-grades .team-grade {
    display: flex;
    align-items: center;
    flex: 2 0;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    align-items: center; }
    .event-grades .team-grade-1 {
      justify-content: flex-end; }
      .event-grades .team-grade-1 .grade-img {
        margin-left: -5px; }
    .event-grades .team-grade-2 .grade-img {
      margin-right: -5px; }
  .event-grades .grade-img {
    height: 20px;
    width: auto; }
  .event-grades .game-suggestions {
    display: flex;
    margin: 0 25px; }
    .event-grades .game-suggestions .red-border {
      border: 2px solid red !important; }
    .event-grades .game-suggestions .gray-border {
      border: 1px solid #766f6f; }
  .event-grades .game-suggestion {
    padding: 7px 0;
    width: 30px;
    margin: 2px;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    color: #4a4a4a;
    font-size: 17px;
    cursor: pointer;
    font-weight: 700; }
    .event-grades .game-suggestion:hover {
      color: #000; }
    .event-grades .game-suggestion.selected {
      background-color: #064378;
      color: #fff;
      border-color: #064378; }
      .event-grades .game-suggestion.selected:hover {
        background-color: #064378; }
  .event-grades .add-background {
    background-color: #222;
    color: #fff; }

.article-tag {
  position: relative; }
  .article-tag-heading {
    font-family: Tahoma, Arial, sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 1rem;
    position: absolute;
    top: -16px;
    left: 16px;
    background: black;
    color: white;
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
    border-radius: 8px 0 8px 0px;
    padding: 3px 12px;
    z-index: 1; }

aside .tipset-widget {
  background-color: #fde3e6;
  position: sticky;
  z-index: 3;
  top: 44px;
  min-height: 145px;
  margin-top: -16px; }
  aside .tipset-widget-body {
    display: none; }
  aside .tipset-widget .tipset-annons {
    background: #0e69b3;
    display: flex;
    width: 100%;
    color: #fff;
    flex: 1; }
    aside .tipset-widget .tipset-annons-head {
      font-size: 10px;
      line-height: 10px;
      padding: 5px 10px 4px;
      flex: 1; }
    aside .tipset-widget .tipset-annons-text {
      font-size: 11px;
      line-height: 11px;
      font-style: italic;
      padding: 4px 10px 3px; }
    aside .tipset-widget .tipset-annons-icon {
      flex: 1;
      padding: 5px 10px 4px; }
  aside .tipset-widget .tipset-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #064378;
    color: #fff;
    flex-direction: column;
    display: flex;
    align-items: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); }
    aside .tipset-widget .tipset-header-image {
      position: absolute;
      align-self: flex-start;
      left: 10px;
      top: 25px;
      width: 80px; }
      @media screen and (min-width: 768px) and (max-width: 800px) {
        aside .tipset-widget .tipset-header-image {
          left: 6px; } }
      aside .tipset-widget .tipset-header-image > img {
        -webkit-clip-path: circle(49.4% at center);
        clip-path: circle(49.4% at center);
        width: 35px;
        height: auto; }
      aside .tipset-widget .tipset-header-image-heading {
        position: absolute;
        top: 0;
        left: 18px;
        font-family: Verdana, sans-serif;
        transform: scaleX(0.736);
        font-weight: 700;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 17px;
        font-style: italic; }
        aside .tipset-widget .tipset-header-image-heading::after {
          content: "";
          position: absolute;
          right: -14px;
          top: 38%;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 5px solid white;
          clear: both; }
      aside .tipset-widget .tipset-header-image:hover {
        cursor: pointer;
        opacity: 0.95; }
    aside .tipset-widget .tipset-header .expert-selector {
      position: absolute;
      z-index: 2;
      top: 40px;
      left: 2px;
      background: white;
      color: #222222;
      font-size: 10px;
      border-radius: 2px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
      padding: 0;
      min-width: 90px; }
      aside .tipset-widget .tipset-header .expert-selector::after {
        content: "";
        position: absolute;
        left: 71px;
        top: -5px;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid white;
        clear: both;
        transform: rotate(180deg); }
        @media screen and (min-width: 768px) and (max-width: 800px) {
          aside .tipset-widget .tipset-header .expert-selector::after {
            left: 67px; } }
      aside .tipset-widget .tipset-header .expert-selector .tipset-header-image {
        position: relative;
        left: unset;
        top: unset;
        padding: 4px 7px 3px;
        width: unset;
        transition: all 0.2s ease;
        border-bottom: 1px solid #fde3e6; }
        aside .tipset-widget .tipset-header .expert-selector .tipset-header-image img {
          display: block; }
        aside .tipset-widget .tipset-header .expert-selector .tipset-header-image .expert-name {
          white-space: nowrap; }
        aside .tipset-widget .tipset-header .expert-selector .tipset-header-image:hover {
          cursor: pointer;
          background: #fde3e6;
          transition: all 0.2s ease; }
        aside .tipset-widget .tipset-header .expert-selector .tipset-header-image.selected {
          background: #fde3e6;
          cursor: auto; }
        aside .tipset-widget .tipset-header .expert-selector .tipset-header-image:first-child {
          border-radius: 4px 4px 0 0;
          padding-top: 6px; }
        aside .tipset-widget .tipset-header .expert-selector .tipset-header-image:last-child {
          border-radius: 0 0 4px 4px;
          padding-bottom: 6px;
          margin-bottom: 0;
          border-bottom: unset; }
    aside .tipset-widget .tipset-header-text {
      font-family: Verdana, sans-serif;
      transform: scaleX(0.75);
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      margin: 3px 0 2px;
      text-align: center; }
    aside .tipset-widget .tipset-header-logo {
      margin: 4px 0 2px;
      height: 18px;
      width: 106px;
      background: url(/sportbladet/speltips/logo-stryktipset-300x50.png) no-repeat;
      background-size: 100% auto; }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        aside .tipset-widget .tipset-header-logo {
          margin-left: 23px; } }
    aside .tipset-widget .tipset-header-week {
      position: absolute;
      top: 26px;
      right: 8px;
      text-transform: uppercase;
      line-height: 11px;
      font-size: 10px;
      text-align: right; }
    aside .tipset-widget .tipset-header-tag {
      line-height: 11px;
      font-size: 10px;
      align-self: flex-end;
      padding: 0 8px;
      text-align: right; }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        aside .tipset-widget .tipset-header-tag {
          padding-left: 30px;
          align-self: center; }
          aside .tipset-widget .tipset-header-tag span {
            display: none; } }
      aside .tipset-widget .tipset-header-tag.no-expert button::first-letter {
        text-transform: uppercase; }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        aside .tipset-widget .tipset-header-tag.no-expert span {
          display: unset; } }
      aside .tipset-widget .tipset-header-tag button {
        font-size: inherit;
        font-family: inherit; }
        aside .tipset-widget .tipset-header-tag button.link:disabled {
          text-decoration: none;
          opacity: 1;
          cursor: default; }
    aside .tipset-widget .tipset-header-bottom {
      padding: 6px 10px 8px;
      display: flex;
      flex-direction: row;
      align-self: stretch;
      justify-content: space-between; }
    aside .tipset-widget .tipset-header-gamestop-time {
      flex: 0 1 auto;
      align-self: flex-start;
      width: 48%;
      line-height: 11px;
      font-size: 10px;
      text-align: left; }
    aside .tipset-widget .tipset-header-game-turnaround {
      flex: 0 1 auto;
      align-self: flex-end;
      width: 51%;
      line-height: 11px;
      font-size: 10px;
      text-align: right;
      white-space: nowrap; }
    aside .tipset-widget .tipset-header .tipset-widget-system {
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      line-height: 19px;
      background: #f4f4f4;
      color: #222222;
      padding: 0;
      width: 100%; }
      aside .tipset-widget .tipset-header .tipset-widget-system .col {
        text-align: center;
        flex: 1 0 auto;
        padding: 4px;
        cursor: pointer; }
        aside .tipset-widget .tipset-header .tipset-widget-system .col:hover, aside .tipset-widget .tipset-header .tipset-widget-system .col.selected {
          padding-bottom: 0;
          border-bottom: 4px solid #3296d8;
          color: black; }
        aside .tipset-widget .tipset-header .tipset-widget-system .col:hover {
          cursor: pointer;
          opacity: 0.9; }
  aside .tipset-widget .teaser-match-odds .column {
    flex: 0 0 33%; }
  aside .tipset-widget .tipset-widget-body-hide {
    display: none; }
  aside .tipset-widget .tipset-footer {
    position: sticky;
    bottom: 0;
    z-index: 9;
    background: #4a4a4a;
    text-align: center;
    padding: 10px;
    box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between; }
    aside .tipset-widget .tipset-footer .switch__label::after {
      content: "";
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite; }
    aside .tipset-widget .tipset-footer-button {
      color: white;
      background: #0cc523;
      border-radius: 50px;
      line-height: 13px;
      font-size: 11px;
      text-align: center;
      letter-spacing: 0.5px;
      padding: 8px 1em;
      text-transform: uppercase; }
      aside .tipset-widget .tipset-footer-button:disabled {
        background: #979797; }
      aside .tipset-widget .tipset-footer-button .amount {
        display: inline-block;
        text-transform: none;
        margin-right: 8px; }
  aside .tipset-widget .teaser-match-heading {
    padding: 8px 29px; }
  aside .tipset-widget .teaser-match-odds {
    font-size: 9px;
    line-height: 14px;
    padding: 1px 10px; }
    aside .tipset-widget .teaser-match-odds-odds {
      font-size: 11px; }
  aside .tipset-widget.open {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 44px); }
    aside .tipset-widget.open .tipset-widget-body {
      display: block; }
    aside .tipset-widget.open .tipset-footer .switch__label::after {
      animation: none; }

aside .remove-margin {
  margin: 0px; }

aside .tipset-widget .teaser-match {
  margin-bottom: 12px; }

aside .event-grades {
  padding: 6px; }
  aside .event-grades .game-suggestion {
    width: 25px;
    padding: 4px 0; }

.spinner {
  width: 40px;
  margin-left: 48%; }

.article-list a {
  text-decoration: none;
  color: #222222; }

@keyframes pulse-dot {
  0% {
    transform: scale(0.8); }
  50% {
    transform: scale(1); }
  100% {
    transform: scale(0.8); } }
