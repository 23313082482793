@import "articles-kris";

.articles-list-main-page,
.article-main-page {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .tab-content {
    // displays when tab content is empty (should be hidden on server side)
    .empty-message {
      min-height: 300px;
      padding: 1rem;
      font-size: 14px;
    }
  }

  .tab-content.menubartabContent {
    margin-top: 5px;

    @media only screen and (max-width: 589px) {
      padding: 0 16px;
    }
  }

  .speltipdesc {
    font: 18px Arial, Helvetica, Verdana, Geneva, sans-serif;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 2px;
    margin-top: 10px;
  }

  .speltipdesc + .speltipdesc {
    text-indent: 1em;
  }

  .speltipsPara {
    font: 16px Arial, Helvetica, Verdana, Geneva, sans-serif;
    line-height: 1.3;
    margin-top: 0;
  }

  .speltipsPara + .speltipsPara {
    text-indent: 1em;
  }

  .point-texts {
    margin: 15px;

    .speltipsPara {
      margin-bottom: 5px;
      text-indent: 0em;

      .point-num {
        background-color: red;
        padding: 2px 5px;
        border-radius: 4px;
        color: white;
      }

      .point-desc {
        margin-left: 5px;
      }
    }
  }

  .article-image-container {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 20px;
    margin-bottom: 20px;

    @media only screen and (min-width: map-get($breakpoints, small)) {
      margin-left: unset;
      margin-right: unset;
    }
  }

  .article-image {
    display: block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    // height: 200px;
    // @media only screen and (max-width: 589px) {
    //  height: 135px;
    // }
    @media only screen and (min-width: map-get($breakpoints, large)) {
      width: 100%;
    }
  }

  .article-image-credits {
    text-transform: uppercase;
    font-family: Arial;
    font-size: 12px;
    line-height: 1.42;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 4px;

    @media only screen and (min-width: map-get($breakpoints, small)) {
      margin-left: unset;
    }
  }

  .articles-list,
  .article {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; // -webkit-flex-wrap: wrap;
    // -ms-flex-wrap: wrap;
    // flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    width: 100%;

    @media only screen and (min-width: map-get($breakpoints, small)) {
      padding-top: 16px;
    }

    .article-list-main-box,
    .article-main-box {
      .speltips-header {
        padding: 6px 16px;
        border-top-width: 3px !important;
        border-top-style: solid;
        margin-bottom: 16px;
        border-top-color: #dd2a30 !important;
        background-color: #fff;

        .spel-para {
          line-height: 1.3;
          margin-top: 7px;
          font-size: 14px;
          padding-top: 8px;
        }
      }

      .article-list {
        border-bottom: 1px solid rgba(34, 34, 34, 0.1);
        margin-bottom: 5px;
        margin-top: 35px;
        transition: all 0.2s ease;

        .teaser-match {
          .teaser-match-heading {
            .team {
              .team-logo-right {
                margin-left: 6px;
                float: right;
              }
            }
          }
        }

        .article-list-desc-link {
          width: 100%;
          position: relative;
          padding-bottom: 4px;
          text-decoration: none;
          color: #000;
          box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.07);
          transition: all 0.15s ease;

          &:hover {
            box-shadow: 0 0px 3px 1px rgba(0, 0, 0, 0.15);
          }

          h3 {
            font-size: 34px;
            letter-spacing: -1.4px;

            @media only screen and (max-width: 767px) {
              font-size: 22px;
            }
          }

          &:hover h3 {
            text-decoration: underline;
          }
        }

        .article-list-data {
          padding: 10px 16px;
        }

        .article-list-desc-link,
        .article-list-data {
          background-color: #feebee;
          display: block;

          .article-list-des-img {
            display: block;

            .spel-image {
              position: relative;

              .image-class,
              .spel-image > img {
                width: 100%;
                position: absolute;
                left: 0;
              }

              .spel-image > img {
                max-width: 168px;
                max-height: 160px;
                top: 3%;
              }
            }
          }

          .article-list-des-img-left {
            display: block;
            width: 30%;
            float: left;
            max-width: 180px;
            margin: 10px;

            .spel-image-left {
              position: relative;
            }

            .image-class,
            .spel-image > img {
              width: 100%;
            }
          }

          .article-list-des-img-right {
            display: block;
            width: 30%;
            float: right;
            max-width: 180px;
            margin: 10px;

            .spel-image-right {
              position: relative;
            }

            .image-class,
            .spel-image > img {
              width: 100%;
            }
          }
        }

        .article-list-title {
          font-size: 34px;
          letter-spacing: -1.4px;
          margin: 0 0 8px;
          color: #000;
          line-height: 1.08em;
          max-width: 100%;
        }

        .article-list-title:hover {
          text-decoration: underline;
        }

        .article-list-desc-sub-title {
          font-size: 16px;
          margin-top: 4px;
        }
      }
    }
  }

  .default-suggestion-link {
    font-size: 16px;
    font-weight: bold;
    color: #a22b15;
    cursor: pointer;
  }

  .date-and-tags {
    line-height: 1.2;
    color: rgba(34, 34, 34, 0.5);
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 4px;
    display: flex;

    .datetime {
      margin-right: 15px;
    }

    .article-tags {
      font-weight: 700;
      color: $brand-color;
      margin-right: 15px;
      text-transform: uppercase;
    }
  }
}

.articles-list-main-page,
.article-main-page {
  .article-image {
    width: 100%;
  }
}

@media screen and (min-width: $minWidth768px) {
  .articles-list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;

    .articles-list-main {
      .sportsbladet_layout {
        flex-direction: row;
      }
    }
  }
}

h3 {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.event-grades {
  justify-content: center;
  display: flex;
  flex: 0 0 12%;
  padding: 5px 10px;
  align-items: center;
  color: #222222;

  .team-grade {
    display: flex;
    align-items: center;
    flex: 2 0;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;

    &-1 {
      justify-content: flex-end;

      .grade-img {
        margin-left: -5px;
      }
    }

    &-2 {
      .grade-img {
        margin-right: -5px;
      }
    }
  }

  .grade-img {
    height: 20px;
    width: auto;
  }

  .game-suggestions {
    display: flex;
    margin: 0 25px;

    .red-border {
      border: 2px solid red !important ;
    }

    .gray-border {
      border: 1px solid #766f6f;
    }
  }

  .game-suggestion {
    padding: 7px 0;
    width: 30px;
    margin: 2px;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    color: #4a4a4a;
    font-size: 17px;
    cursor: pointer;
    font-weight: 700;

    &:hover {
      color: #000;
    }

    &.selected {
      background-color: #064378;
      color: #fff;
      border-color: #064378;

      &:hover {
        background-color: #064378;
      }
    }
  }

  // this should be replaced by .selected (see above)
  .add-background {
    background-color: #222;
    color: #fff;
  }
}

.article-tag {
  position: relative;

  &-heading {
    font-family: Tahoma, Arial, sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 1rem;
    position: absolute;
    top: -16px;
    left: 16px;
    background: black;
    color: white;
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
    border-radius: 8px 0 8px 0px;
    padding: 3px 12px;
    z-index: 1;
  }
}

aside {
  .tipset-widget {
    background-color: $brand-bg-color;
    position: sticky;
    z-index: 3;
    top: 44px;
    min-height: 145px;
    margin-top: -16px;

    &-body {
      display: none;
    }

    .tipset-annons {
      background: #0e69b3;
      display: flex;
      width: 100%;
      color: #fff;
      flex: 1;

      &-head {
        font-size: 10px;
        line-height: 10px;
        padding: 5px 10px 4px;
        flex: 1;
      }

      &-text {
        font-size: 11px;
        line-height: 11px;
        font-style: italic;
        padding: 4px 10px 3px;
      }
      &-icon {
        flex: 1;
        padding: 5px 10px 4px;
      }
    }

    .tipset-header {
      position: sticky;
      top: 0;
      z-index: 10;
      background: #064378;
      color: #fff;
      flex-direction: column;
      display: flex;
      align-items: center;
      //      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

      &-image {
        position: absolute;
        align-self: flex-start;
        left: 10px;
        top: 25px;
        width: 80px;
        @media screen and (min-width: 768px) and (max-width: 800px) {
          left: 6px;
        }
        & > img {
          -webkit-clip-path: circle(49.4% at center);
          clip-path: circle(49.4% at center);
          width: 35px;
          height: auto;
        }
        &-heading {
          position: absolute;
          top: 0;
          left: 18px;
          font-family: Verdana, sans-serif;
          transform: scaleX(0.736);
          font-weight: 700;
          text-transform: uppercase;
          font-size: 18px;
          line-height: 17px;
          font-style: italic;

          &::after {
            content: "";
            position: absolute;
            right: -14px;
            top: 38%;
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 5px solid white;
            clear: both;
          }
        }
        &:hover {
          cursor: pointer;
          opacity: 0.95;
        }
      }

      .expert-selector {
        position: absolute;
        z-index: 2;
        top: 40px;
        left: 2px;
        background: white;
        color: $text-color;
        font-size: 10px;
        border-radius: 2px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
        padding: 0;
        min-width: 90px;

        &::after {
          content: "";
          position: absolute;
          left: 71px;
          @media screen and (min-width: 768px) and (max-width: 800px) {
            left: 67px;
          }
          top: -5px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 6px solid white;
          clear: both;
          transform: rotate(180deg);
        }

        .tipset-header-image {
          position: relative;
          left: unset;
          top: unset;
          padding: 4px 7px 3px;
          width: unset;
          transition: all 0.2s ease;
          border-bottom: 1px solid $brand-bg-color;

          img {
            display: block;
          }

          .expert-name {
            white-space: nowrap;
          }

          &:hover {
            cursor: pointer;
            background: $brand-bg-color;
            transition: all 0.2s ease;
          }
          &.selected {
            background: $brand-bg-color;
            cursor: auto;
          }
          &:first-child {
            border-radius: 4px 4px 0 0;
            padding-top: 6px;
          }
          &:last-child {
            border-radius: 0 0 4px 4px;
            padding-bottom: 6px;
            margin-bottom: 0;
            border-bottom: unset;
          }
        }
      }

      &-text {
        font-family: Verdana, sans-serif;
        transform: scaleX(0.75);
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        margin: 3px 0 2px;
        text-align: center;
      }

      &-logo {
        margin: 4px 0 2px;
        height: 18px;
        width: 106px;
        background: url(/sportbladet/speltips/logo-stryktipset-300x50.png) no-repeat;
        background-size: 100% auto;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          margin-left: 23px;
        }
      }

      &-week {
        position: absolute;
        top: 26px;
        right: 8px;
        text-transform: uppercase;
        line-height: 11px;
        font-size: 10px;
        text-align: right;
      }

      &-tag {
        line-height: 11px;
        font-size: 10px;
        align-self: flex-end;
        padding: 0 8px;
        text-align: right;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          padding-left: 30px;
          align-self: center;
          span {
            display: none;
          }
        }
        &.no-expert {
          button::first-letter {
            text-transform: uppercase;
          }
          @media screen and (min-width: 768px) and (max-width: 1023px) {
            span {
              display: unset;
            }
          }
        }
        button {
          font-size: inherit;
          font-family: inherit;
          &.link:disabled {
            text-decoration: none;
            opacity: 1;
            cursor: default;
          }
        }
      }

      &-bottom {
        padding: 6px 10px 8px;
        display: flex;
        flex-direction: row;
        align-self: stretch;
        justify-content: space-between;
      }

      &-gamestop-time {
        flex: 0 1 auto;
        align-self: flex-start;
        width: 48%;
        line-height: 11px;
        font-size: 10px;
        text-align: left;
      }

      &-game-turnaround {
        flex: 0 1 auto;
        align-self: flex-end;
        width: 51%;
        line-height: 11px;
        font-size: 10px;
        text-align: right;
        white-space: nowrap;
      }

      .tipset-widget-system {
        display: flex;
        justify-content: space-between;
        font-size: 11px;
        line-height: 19px;
        background: #f4f4f4;
        color: $text-color;
        padding: 0;
        width: 100%;

        .col {
          text-align: center;
          flex: 1 0 auto;
          padding: 4px;
          cursor: pointer;

          &:hover,
          &.selected {
            padding-bottom: 0;
            border-bottom: 4px solid #3296d8;
            color: black;
          }
          &:hover {
            cursor: pointer;
            opacity: 0.9;
          }
        }
      }
    }

    .teaser-match-odds {
      .column {
        flex: 0 0 33%;
      }
    }

    .tipset-widget-body-hide {
      display: none;
    }

    .tipset-footer {
      position: sticky;
      bottom: 0;
      z-index: 9; // Must be 1 less than .tipset-header
      background: #4a4a4a;
      text-align: center;
      padding: 10px;
      //      box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.4);
      box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: space-between;

      .switch__label {
        &::after {
          content: "";
          animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
        }
      }

      &-button {
        color: white;
        background: #0cc523;
        border-radius: 50px;
        line-height: 13px;
        font-size: 11px;
        text-align: center;
        letter-spacing: 0.5px;
        padding: 8px 1em;
        text-transform: uppercase;

        &:disabled {
          background: #979797;
        }

        .amount {
          display: inline-block;
          text-transform: none;
          margin-right: 8px;
        }
      }
    }

    .teaser-match-heading {
      padding: 8px 29px;
    }

    .teaser-match-odds {
      font-size: 9px;
      line-height: 14px;
      padding: 1px 10px;

      &-odds {
        font-size: 11px;
      }
    }

    &.open {
      overflow-x: hidden;
      overflow-y: scroll;
      height: calc(100vh - 44px);

      .tipset-widget-body {
        display: block;
      }
      .tipset-footer .switch__label::after {
        animation: none;
      }
    }
  }

  .remove-margin {
    margin: 0px;
  }

  .tipset-widget .teaser-match {
    margin-bottom: 12px;
  }

  .event-grades {
    padding: 6px;

    .game-suggestion {
      width: 25px;
      padding: 4px 0;
    }
  }
}

.spinner {
  width: 40px;
  margin-left: 48%;
}

.article-list a {
  text-decoration: none;
  color: $text-color;
}

@keyframes pulse-dot {
  // based on https://codepen.io/peeke/pen/BjxXZa
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
