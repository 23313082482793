.stats {
    padding: 10px 16px;
  text-align: center;
  font-size: 12px;

  &-graph {
    padding-bottom: 10px;
    display: inline-flex;
    width: 100%;
    min-height: 1px;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  &-score {
    width: 10%;
  }


  &-progress-bar-div {
    width: 80%;

    .stats-progress-bar {
      display: flex;

      &-left {
        width: 50%;

        .progress-left {
          direction: rtl;
          width: 100%;
        }

        & > progress,
        progress[role] {
          -webkit-appearance: none;
          appearance: none;
          border: none;
          width: 100%;
        }

        &>progress[value]::-webkit-progress-bar {
          background-color: lightgrey;
        }

        &>progress[value]::-webkit-progress-value {
          background-color: green;
        }
      }

      &-right {
        width: 50%;

        .progress-right {
          direction: ltr;
          width: 100%;
        }

        & > progress,
        progress[role] {
          -webkit-appearance: none;
          appearance: none;
          border: none;
          width: 100%;
        }

        &>progress[value]::-webkit-progress-bar {
          background-color: lightgrey;
        }

        &>progress[value]::-webkit-progress-value {
          background-color: gray;
        }
      }
    }
  }
}

.team-names {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0;
  font-size: 16px;
  font-weight: bold;

  .team-name {
  }
}