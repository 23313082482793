/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: normal 125% / 1.4 $text-font-stack;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Verdana,Arial,Helvetica,sans-serif;
  font-weight: 700;
  line-height: 1.1;
  margin: 0;
}

h1 {
  font-size: 50px; // was 34px;
  font-size: 1.625rem;
  line-height: 1.08;
  letter-spacing: -.05rem;
  margin-bottom: 8px;
}

@media only screen and (min-width: map-get($breakpoints, small)) {
  h1 {
    font-size: 2.8125rem;
  }
}

@media only screen and (min-width: map-get($breakpoints, large)) {
  h1 {
    font-size: 3.125rem;
  }
}

